import { postJsonToApi } from "./requestManager";
import { PRICING_TEST_MAY_2024, THREE_MONTHS_50_OFF_PARAM } from "../features/apptimize/apptimizeVariables";
import { getGAClientId } from "../features/tracking/trackingSlice";
import { store } from "../app/store";

const STRIPE_BASE_PATH = 'stripe';
const CREATE_CHECKOUT_SESSION_PATH = STRIPE_BASE_PATH + '/create_checkout_session';
const CHECKOUT_SESSION_STATUS_PATH = STRIPE_BASE_PATH + '/checkout_session_status';
const CREATE_CUSTOMER_PORTAL_SESSION_PATH = STRIPE_BASE_PATH + '/create_customer_portal_session';

export const stripeServices = {
  checkout,
  checkoutSessionStatus,
  customerPortal,
}

interface StripeCheckoutAPI
{
  client_secret: string,
  session_id: string
}

interface StripeCustomerPortalAPI
{
  session_id: string,
  url: string,
}

interface StripeCheckoutSessionStatusAPI
{
  status: string
}

function getPricingTestParams()
{
  return {
    [PRICING_TEST_MAY_2024]: THREE_MONTHS_50_OFF_PARAM,
  };
}

async function checkout()
{
  const jsonBody = getPricingTestParams();

  return postJsonToApi<StripeCheckoutAPI>( CREATE_CHECKOUT_SESSION_PATH, {}, jsonBody );
}

async function checkoutSessionStatus( sessionId: string )
{
  const googleClientId = getGAClientId( store.getState() );

  const jsonBody = {
    session_id: sessionId,
    ga_client_id: googleClientId,
  };

  return postJsonToApi<StripeCheckoutSessionStatusAPI>( CHECKOUT_SESSION_STATUS_PATH, {}, jsonBody );
}

function customerPortal()
{
  postJsonToApi<StripeCustomerPortalAPI>( CREATE_CUSTOMER_PORTAL_SESSION_PATH, {} ).then( ( response ) =>
  {
    window.location.replace( response.url );
  } )
}
