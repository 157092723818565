import React, { useEffect } from 'react'
import { businessServices, FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../../services/business.services";
import { SocialMediaAccountRow } from "./SocialMediaAccountRow";
import { Typography } from "@mui/material";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";

export function SocialNetworkAccountsSection()
{
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );

  useEffect( () =>
  {
    businessServices.listSocialNetworkAccounts().then( ( response ) =>
    {
      setSocialNetworkAccounts( response.social_network_accounts );
    } )

  }, [] );

  function getFacebookRow()
  {
    const accountType = FACEBOOK_PAGE;
    const connectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
    if ( connectedFacebookAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookAccount} otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}/>
    }
    else
    {
      return <ConnectFacebookPagesButton fromSettings={true}/>
    }
  }

  function getInstagramRow()
  {
    const accountType = FACEBOOK_INSTAGRAM;
    const connectedInstagramAccount = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
    if ( connectedInstagramAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedInstagramAccount} otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}/>
    }
    else
    {
      return <ConnectFacebookInstagramButton fromSettings={true}/>
    }
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  return (
    <>
      <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
        <Typography variant={"h6"} sx={{ textDecoration: "none" }}>Social Network Accounts</Typography>
        {getFacebookRow()}
        {getInstagramRow()}
      </SocialNetworkAccountsContext.Provider>
    </>
  )
}


