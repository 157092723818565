import { clearBusiness } from "../business/businessSlice";
import { clearPosts } from "../postIdea/postsSlice";
import { clearUserData } from "./userSlice";
import { clearUI } from "../ui/uiSlice";
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import { clearAssistantChat } from "../assistantChat/assistantChatSlice";
import { chatServices } from "../../services/chat.services";
import { clearApptimizeParticipationState } from "../apptimize/apptimizeSlice";
import { apptimizeWrapper } from "../apptimize/apptimizeWrapper";
import { clearTracking } from "../tracking/trackingSlice";
import { clearAlertMessage } from "../alert/alertSlice";
import { clearAbortController, getAbortController } from "../../services/requestManager";
import { clearPostIdeas } from "../postIdea/postIdeaSlice";

export const signOutUser = ( dispatch ) =>
{
  getAbortController().abort();
  clearAbortController();
  apptimizeWrapper.takeOffline();

  chatServices.clearLongRunningFunctionTimeout();
  chatServices.clearCheckMessagesTimeout();
  dispatch( clearAlertMessage() );
  dispatch( clearBusiness() );
  dispatch( clearPosts() );
  dispatch( clearUserData() );
  dispatch( clearUI() );
  dispatch( clearAssistantChat() );
  dispatch( clearApptimizeParticipationState() );
  dispatch( clearTracking() );
  dispatch( clearPostIdeas() );

  apptimizeWrapper.takeOnline();
  amplitude.reset();
  Sentry.setUser( null );
}
