import { Stack } from "@mui/material";
import React from "react";
import { BUSINESS_MEDIA_PICKER_TITLE, MediaAssetSource, MediaPickingMode, ROUTE_SEO } from "../constants";
import { Helmet } from "react-helmet";
import { MediaPicker } from "../mediaPicker/mediaPicker";
import { businessServices, MediaAssetAPI } from "../../services/business.services";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";

export function MediaLibraryTab()
{
  const dispatch = useDispatch();

  async function handleSelectedMediaUrl( selectedUrl: string, mediaSource: MediaAssetSource, onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, selectedUrl: string,
                                                                                                                   mediaSource: MediaAssetSource ) => void )
  {
    try
    {
      await businessServices.addMediaAssetToLibrary( selectedUrl, mediaSource );
      eventTracker.logMediaLibraryMediaAdded( mediaSource );

      if ( onAddMediaSuccess )
      {
        onAddMediaSuccess( undefined, selectedUrl, mediaSource );
      }
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again.", "top" ) ) );
    }
  }

  async function handleSelectedMediaAssets( mediaAssets: MediaAssetAPI[],
                                            mediaSource: MediaAssetSource,
                                            closeAddAfterHandlingSelection: boolean,
                                            onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                                  mediaSource: MediaAssetSource ) => void )
  {
    if ( onAddMediaSuccess )
    {
      onAddMediaSuccess( undefined, mediaAssets, mediaSource );
    }
  }

  return <Stack
    sx={{
      backgroundColor: 'white',
      position: 'relative',
      height: "100%",
      textAlign: "left"
    }}>
    <Helmet>
      <title>{ROUTE_SEO.MEDIA_LIBRARY.title}</title>
      <meta name="description" content={ROUTE_SEO.MEDIA_LIBRARY.description}/>
    </Helmet>
    <MediaPicker openedFromMediaLibraryTab={true}
                 title={BUSINESS_MEDIA_PICKER_TITLE}
                 processSelectedMediaUrl={handleSelectedMediaUrl}
                 processSelectedMediaAssets={handleSelectedMediaAssets}
                 mediaPickingMode={MediaPickingMode.Add}/>
  </Stack>
}

