import { ShareBatchAPI } from "../../services/postIdeaServices";
import { compact, filter, includes, map, size, sortBy } from "lodash";
import { FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SHARING_STATUS_FAILED, SocialNetworkPostAPI } from "../../services/business.services";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useContext, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Property } from "csstype";
import { eventTracker } from "../../helpers/eventTracker";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { SocialNetworkAccountRefreshBar } from "./socialNetworkAccountRefreshBar";
import CancelIcon from '@mui/icons-material/Cancel';

export interface ShareBatchAccordionHeaderProps
{
  shareBatch: ShareBatchAPI;
  backgroundColor: Property.BackgroundColor;
  handleRetrySocialShare?: () => Promise<any>;
}

export function ShareBatchAccordionHeader( props: ShareBatchAccordionHeaderProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const [disableRetryButton, setDisableRetryButton] = useState( false );

  const allSocialNetworkPosts = props.shareBatch.social_network_posts || [];
  const allSocialNetworkPostsSorted = sortBy( allSocialNetworkPosts, ( socialNetworkPost ) => socialNetworkPost.account_type );
  const failedSocialNetworkPosts = filter( props.shareBatch.social_network_posts || [],
    ( socialNetworkPost ) => socialNetworkPost.send_status === SHARING_STATUS_FAILED );
  const failedFacebookPagePosts = filter( failedSocialNetworkPosts,
    ( socialNetworkPost ) => socialNetworkPost.account_type === FACEBOOK_PAGE );
  const failedInstagramPosts = filter( failedSocialNetworkPosts,
    ( socialNetworkPost ) => socialNetworkPost.account_type === FACEBOOK_INSTAGRAM );
  const failedSocialNetworkPostAccountIds = map( failedSocialNetworkPosts, ( socialNetworkPost ) => socialNetworkPost.social_network_account_id );
  const socialNetworkAccountsToRefresh = compact( map( socialNetworkAccountsContext.socialNetworkAccounts,
    ( account ) =>
    {
      if ( includes( failedSocialNetworkPostAccountIds, account.id ) && account.invalid_token_error_count && account.invalid_token_error_count > 0 )
      {
        return account;
      }
      return null
    } ) );

  function handleErrorBannerClicked( event: React.MouseEvent<HTMLDivElement> )
  {
    event.stopPropagation();
  }

  function handleRetry( event: React.MouseEvent<HTMLButtonElement> )
  {
    eventTracker.logRetryButtonClicked( failedSocialNetworkPosts, props.shareBatch );
    event.stopPropagation();
    if ( !!props.handleRetrySocialShare )
    {
      setDisableRetryButton( true );
      props.handleRetrySocialShare().finally( () =>
      {
        setDisableRetryButton( false );
      } );
    }
  }

  function getErrorMessage()
  {
    if ( size( failedFacebookPagePosts ) > 0 && size( failedInstagramPosts ) > 0 )
    {
      return "Sharing to Facebook & Instagram failed";
    }
    else if ( size( failedFacebookPagePosts ) > 0 )
    {
      return "Sharing to Facebook failed";
    }
    else if ( size( failedInstagramPosts ) > 0 )
    {
      return "Sharing to Instagram failed";
    }
  }

  function getIconForAccountType( socialNetworkPost: SocialNetworkPostAPI )
  {
    if ( socialNetworkPost.account_type === FACEBOOK_PAGE )
    {
      return <FacebookIcon key={socialNetworkPost.social_network_account_id} sx={{ color: "white" }}/>;
    }
    else if ( socialNetworkPost.account_type === FACEBOOK_INSTAGRAM )
    {
      return <InstagramIcon key={socialNetworkPost.social_network_account_id} sx={{ color: "white" }}/>;
    }
    return null;
  }

  function renderFailedStatusBar()
  {
    if ( size( failedSocialNetworkPosts ) > 0 && size( socialNetworkAccountsToRefresh ) > 0 )
    {
      return compact( map( socialNetworkAccountsToRefresh,
        ( socialNetworkAccount ) => <SocialNetworkAccountRefreshBar key={socialNetworkAccount.id} socialNetworkAccount={socialNetworkAccount}/> ) );
    }
    else if ( size( failedSocialNetworkPosts ) > 0 )
    {
      return (<Box sx={{ width: "100%", backgroundColor: "#DB1B50", cursor: "default" }} onClick={handleErrorBannerClicked}>
        <Stack direction="row" alignItems="center" justifyContent="start" sx={{ textAlign: "center", py: 2, px: 1, ml: 5 }}>
          <ErrorOutlineIcon sx={{ color: "white", height: "20px", width: "20px" }}/>
          <Typography sx={{ mx: 1, textAlign: "left", fontSize: "12px", color: "white", fontWeight: "800" }}>{getErrorMessage()}</Typography>
          <Tooltip title="Sharing to one or more social accounts failed, please retry or contact support" enterTouchDelay={50}>
            <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
              <HelpOutlineIcon sx={{ color: "white", height: "15px", width: "15px" }}/>
            </Box>
          </Tooltip>
          {props.handleRetrySocialShare && <Button variant={"contained"} disabled={disableRetryButton} onClick={handleRetry} sx={{
            ml: "auto", mr: 1, backgroundColor: "white", color: "#DB1B50", fontSize: "10px", fontWeight: "400",
            '&:hover': { backgroundColor: '#F2F2F2', color: '#CB0046' },
          }}>Retry</Button>}
        </Stack>
      </Box>);
    }
    return null
  }

  return <Stack>
    {size( allSocialNetworkPostsSorted ) > 0 && <Stack direction="row" justifyContent="flex-end" alignItems="center"
                                                       sx={{ backgroundColor: props.backgroundColor, pr: 2, pb: 2 }}
                                                       spacing={2}>
      {map( allSocialNetworkPostsSorted, ( socialNetworkPost, index ) =>
        {
          return <Box sx={{ position: "relative", height: "24px" }} key={socialNetworkPost.social_network_account_id}>
            {getIconForAccountType( socialNetworkPost )}
            {socialNetworkPost.send_status === SHARING_STATUS_FAILED && <CancelIcon
              sx={{ position: "absolute", bottom: -2, right: -2, fontSize: 14, fill: "red", background: "white", borderRadius: "100%" }}/>}
          </Box>
        }
      )}
    </Stack>}
    {renderFailedStatusBar()}
  </Stack>
}