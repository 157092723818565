import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Stack, SxProps, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getProgress } from "../ui/uiSlice";
import { RootState } from "../../app/store";
import { merge } from "lodash";

export interface ProgressOverlayProps
{
  hideBackDrop?: boolean,
  wheelColor?: string,
  circleSize?: number,
  message?: string;
  sx?: SxProps,
}

export default function ProgressOverlay( props: ProgressOverlayProps )
{
  const progressState = useSelector( ( state: RootState ) => getProgress( state ) );
  const message = props.message || progressState?.message;
  const circleSize = props.circleSize || 100;

  function getDeterminateProgress()
  {
    if ( !!progressState )
    {
      return <Box display='flex' justifyContent='center' alignItems='center'>
        <Stack spacing={2} alignItems='center'>
          <CircularProgress size={circleSize} color="inherit" value={progressState.progress} variant={"determinate"}/>
          {!!progressState.progress && <Typography variant={"h6"}>{progressState.progress}%</Typography>}
          {message && <Typography variant={"h6"}>{message}</Typography>}
        </Stack>
      </Box>;
    }
    return <></>;
  }

  function getIndeterminateProgress()
  {
    const progressMessage = props.message || progressState?.message;

    return <Box display='flex' justifyContent='center' alignItems='center'>
      <Stack spacing={2} alignItems='center'>
        <CircularProgress size={circleSize} color="inherit"/>
        {progressMessage && <Typography variant={"h6"}>{progressMessage}</Typography>}
      </Stack>
    </Box>;
  }

  const shouldShowDeterminateProgress = !!progressState && !!progressState.progress;

  return (
    <div>
      <Backdrop
        invisible={props.hideBackDrop}
        sx={merge( {
          color: !!props.wheelColor ? props.wheelColor : "primary.main", zIndex: ( theme ) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(1px)",
          backgroundColor: "rgb(255, 255, 255, 0.5)"
        }, props.sx )}
        open
      >
        {shouldShowDeterminateProgress ? getDeterminateProgress() : getIndeterminateProgress()}
      </Backdrop>
    </div>
  );
}