import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BrandStyleAPI,
  ClientUiFlagsJsonAPI,
  FacebookPagesAPI,
  InstagramAccountsAPI,
  RefreshBusinessAPI,
  UnsubscribedNotificationsAPI,
  WeeklyScheduleAPI,
} from "../../services/business.services";
import { RootState } from "../../app/store";

export interface BusinessState
{
  name: string,
  description: string,
  business_id: string,
  facebook_connected?: boolean,
  has_instagram_accounts?: boolean,
  instagram_account?: string,
  instagram_accounts?: InstagramAccountsAPI,
  instagram_account_profile_image_url?: string,
  has_multiple_instagram_accounts?: boolean,
  facebook_page?: string,
  facebook_page_accounts?: FacebookPagesAPI,
  facebook_page_profile_image_url?: string,
  has_multiple_facebook_page_accounts?: boolean
  posts_per_week_goal?: number,
  weekly_schedule?: WeeklyScheduleAPI,
  has_approved_topics: boolean;
  first_post_drafted: boolean;
  created_at?: string;
  active_business_media_styles_count?: number;
  unsubscribed_notifications: UnsubscribedNotificationsAPI;
}

const initialState: BusinessState = {
  business_id: "",
  name: "",
  description: "",
  has_approved_topics: false,
  first_post_drafted: false,
  unsubscribed_notifications: {
    unsubscribed_all_notifications: false,
  },
}

export const businessSlice = createSlice( {
  name: 'business',
  initialState,
  reducers: {
    clearBusiness: ( state ) =>
    {
      return {
        ...initialState
      }
    },
    updateBusiness: ( state, action: PayloadAction<RefreshBusinessAPI> ) =>
    {
      return {
        ...state,
        id: action.payload.business_id,
        business_id: action.payload.business_id,
        name: action.payload.name,
        description: action.payload.description,
        facebook_connected: action.payload.facebook_connected,
        has_instagram_accounts: action.payload.has_instagram_accounts,
        has_multiple_instagram_accounts: action.payload.has_multiple_instagram_accounts,
        has_approved_topics: action.payload.has_approved_topics,
        instagram_account_name: action.payload.instagram_account_name,
        instagram_account_profile_image_url: action.payload.instagram_account_profile,
        instagram_account_id: action.payload.instagram_account_id,
        has_facebook_page_accounts: action.payload.has_facebook_page_accounts,
        has_multiple_facebook_page_accounts: action.payload.has_multiple_facebook_page_accounts,
        facebook_page_account_name: action.payload.facebook_page_account_name,
        facebook_page_profile_image_url: action.payload.facebook_page_account_profile,
        facebook_page_account_id: action.payload.facebook_page_account_id,
        posts_per_week_goal: action.payload.posts_per_week_goal,
        weekly_schedule: action.payload.weekly_schedule,
        created_at: action.payload.created_at,
        first_post_drafted: action.payload.first_post_drafted,
        brand_style: action.payload.brand_style,
        client_ui_flags_json: action.payload.client_ui_flags_json,
        active_business_media_styles_count: action.payload.active_business_media_styles_count,
        unsubscribed_notifications: action.payload.unsubscribed_notifications,
      }
    },
    setBrandStyle: ( state, action: PayloadAction<BrandStyleAPI> ) =>
    {
      return {
        ...state,
        brand_style: action.payload,
      }
    },
    setInstagramAccounts: ( state, action: PayloadAction<InstagramAccountsAPI> ) =>
    {
      return {
        ...state,
        instagram_accounts: action.payload,
      }
    },
    setFacebookPages: ( state, action: PayloadAction<FacebookPagesAPI> ) =>
    {
      return {
        ...state,
        facebook_page_accounts: action.payload,
      }
    },
    setClientUiFlagsJson: ( state, action: PayloadAction<ClientUiFlagsJsonAPI> ) =>
    {
      return {
        ...state,
        client_ui_flags_json: action.payload,
      }
    },
  },
} )

export const getBusiness = ( state ) => state.business;
export const businessInstagramAccountID = ( state ) => state.business.instagram_account_id;
export const facebookPageAccountID = ( state ) => state.business.facebook_page_account_id;
export const currentUserBusinessId = ( state ) => state.business.business_id;
export const currentUserBusinessCreatedAt = ( state ) => state.business.created_at;
export const getBusinessId = ( state ) => state.business.business_id;
export const getBusinessName = ( state ) => state.business.name;
export const getFirstPostDrafted = ( state ) => state.business.first_post_drafted;
export const hasBrandStyle = ( state ) => !!state.business.brand_style;
export const getBrandStyleFontSet = ( state ) => hasBrandStyle( state ) && state.business.brand_style.font_set;
export const getBrandStyleColorPalette = ( state ) => hasBrandStyle( state ) && state.business.brand_style.color_palette;
export const getBrandStyleLogo = ( state ) => hasBrandStyle( state ) && state.business.brand_style.logo;
export const getHasSeenCustomPlanAnnouncementDialog = ( state ) => state.business.client_ui_flags_json
                                                                   && state.business.client_ui_flags_json.has_seen_custom_plan_announcement_dialog;
export const getHasSeenMediaLibraryAnnouncementDialog = ( state ) => state.business.client_ui_flags_json
                                                                     && state.business.client_ui_flags_json.has_seen_media_library_announcement_dialog;
export const isSubscribedAllNotifications = ( state: RootState ) => !state.business.unsubscribed_notifications.unsubscribed_all_notifications;

// Action creators are generated for each case reducer function
export const {
  clearBusiness,
  updateBusiness,
  setInstagramAccounts,
  setFacebookPages,
  setBrandStyle,
  setClientUiFlagsJson,
} = businessSlice.actions

export default businessSlice.reducer
