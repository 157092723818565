import React, { useContext, useEffect, useState } from 'react'
import "./mediaList.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { Box, Typography } from "@mui/material";
import { isEqual, map, size } from "lodash";
import { getOrderedMediaUrls } from "../postIdea/postDataHelper";
import SortableDesignMediaItem from "./sortableDesignMediaItem";
import { AddMediaButton } from "./AddMediaButton";
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { postIdeaServices } from "../../services/postIdeaServices";
import { PostIdeaContext } from "../context/postIdeaContext";

export interface MediaListProps
{
  loading: boolean;
  handleAddMediaClicked(): void;
  handleRemoveMediaClicked( url: string, removedIndex: number ): void;
}

export function MediaList( props: MediaListProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );

  const orderedMediaUrls = getOrderedMediaUrls( post );
  const [sortedItems, setSortedItems] = useState( orderedMediaUrls );

  const sensors = useSensors(
    useSensor( MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    } ),
    useSensor( TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    } )
  );

  useEffect( () =>
  {
    if ( !!orderedMediaUrls )
    {
      setSortedItems( orderedMediaUrls );
    }
  }, [post] );

  async function handleDragEnd( event )
  {
    const { active, over } = event;

    if ( active && over && active.id !== over.id )
    {
      const oldIndex = sortedItems.indexOf( active.id );
      const newIndex = sortedItems.indexOf( over.id );
      setSortedItems( ( items ) =>
      {
        return arrayMove( items, oldIndex, newIndex );
      } );
      await postIdeaServices.moveSlide( postIdea.id, oldIndex, newIndex );
    }
  }

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ textAlign: "center", paddingBottom: 5 }}>My Post Media</Typography>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <Box sx={{
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "4px",
          display: "grid",
          listStyle: "none",
          padding: 0,
          maxWidth: "321px",
          margin: "0 auto",
        }}>
          <SortableContext
            items={sortedItems}
            strategy={rectSortingStrategy}
          >
            {map( sortedItems, ( url: string ) =>
            {
              return <SortableDesignMediaItem key={url}
                                              url={url}
                                              showDelete={size( orderedMediaUrls ) > 1}
                                              orderedMediaUrls={orderedMediaUrls}
                                              deleteMediaClicked={props.handleRemoveMediaClicked}/>
            } )}
          </SortableContext>

          <AddMediaButton post={post}
                          loading={props.loading}
                          handleAddMediaClicked={props.handleAddMediaClicked}/>
        </Box>
      </DndContext>
    </Box>
  );
}

