import { MediaLibraryState } from "../features/ui/uiSlice";
import { SIGN_IN_PROVIDER_PHONE_NUMBER } from "../features/constants";
import { chain } from "lodash";

export const CURRENT_STORE_VERSION = 15;

export const rootMigrations = {
  0: ( state ) =>
  {
    return state;
  },
  1: ( state ) =>
  {
    return {
      ...state,
      ui: {
        ...state.ui,
        conversation: {
          yield_to_drawer: false,
          last_yield_to_drawer_time: 0,
        }
      }
    }
  },
  2: ( state ) =>
  {
    return {
      ...state,
      loading:
        {
          postIdeaDownloads: {},
          recording: {
            recording_status: null,
            recording_phase_progress: 0,
          }
        }
    }
  },
  3: ( state ) =>
  {
    const previousLoadingState = state.loading;
    delete previousLoadingState.postIdeaDownloads;

    return {
      ...state,
      loading: previousLoadingState,
    }
  },
  4: ( state ) =>
  {
    const mediaLibraryState: MediaLibraryState =
      {
        media_assets: []
      };

    return {
      ...state,
      ui: {
        ...state.ui,
        media_library: mediaLibraryState,
      }
    }
  },
  5: ( state ) =>
  {
    const { phone_verification_required, ...otherAttributes } = state.user;
    const verificationRequired = !!phone_verification_required ? SIGN_IN_PROVIDER_PHONE_NUMBER : null;
    return {
      ...state,
      user: {
        ...otherAttributes,
        verification_required: verificationRequired,
      }
    }
  },
  6: ( state ) =>
  {
    return {
      ...state,
      loading: {
        ...state.loading,
        application: {
          loading: false,
        }
      }
    }
  },
  7: ( state ) =>
  {
    const { id, ...otherAttributes } = state.user;
    return {
      ...state,
      user: {
        ...otherAttributes,
        slug: id,
      }
    }
  },
  8: ( state ) =>
  {
    const { apptimize, ...otherAttributes } = state;
    return {
      ...otherAttributes,
      apptimize: {
        isInitialized: false,
      }
    }
  },
  9: ( state ) =>
  {
    const { tracking, ...otherAttributes } = state;
    delete tracking.sent_first_post_preview_clicked_facebook_event;
    return {
      ...otherAttributes,
      tracking: {
        ...tracking
      }
    }
  },
  10: ( state ) =>
  {
    return {
      ...state,
      inAppNotices: {
        notices: []
      },
    }
  },
  11: ( state ) =>
  {
    const previousUiState = state.ui;
    delete previousUiState.hasSeenExtendedPlan;

    return {
      ...state,
      ui: previousUiState,
    }

  },
  12: ( state ) =>
  {
    const previousUiState = state.ui;

    return {
      ...state,
      ui: {
        ...previousUiState,
        plan: {},
      },
    }
  },
  13: ( state ) =>
  {
    const previousBusinessState = state.business;
    delete previousBusinessState.planner;

    return {
      ...state,
      business: previousBusinessState,
    }
  },
  14: ( state ) =>
  {
    const previousUiState = state.business;

    return {
      ...state,
      business: {
        ...previousUiState,
        unsubscribed_notifications: {
          unsubscribed_all_notifications: false,
        },
      },
    }
  },
  [CURRENT_STORE_VERSION]: ( state ) =>
  {
    const previousPostIdeaMessages = state.assistantChat.postIdeaMessages;
    const previousPostIdeasFromChatAsHashMap = chain( previousPostIdeaMessages )
      .map( ( message ) => message.data )
      .flatten()
      .compact()
      .keyBy( ( postIdea ) => postIdea.id )
      .value();

    return {
      ...state,
      postIdea: {
        postIdeas: {
          ...previousPostIdeasFromChatAsHashMap
        },
      },
    }
  }
}
