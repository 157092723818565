import * as React from "react";
import { isEqual } from "lodash";
import { MusicTrack } from "../ui/uiSlice";

export interface AudioPlayerProps
{
  currentMusic?: MusicTrack
  currentMusicPlaying?: boolean
}

export interface AudioPlayerDispatchProps
{
  clearAudioPlayerMusicTrack();
  setAudioPlayerPlaying( playing: boolean );
}

interface AudioPlayerState
{
  audioElement?: HTMLAudioElement;
  playPromise?: Promise<void>;
}

export class AudioPlayer extends React.PureComponent<AudioPlayerProps & AudioPlayerDispatchProps, AudioPlayerState>
{
  constructor( props )
  {
    super( props );
    this.state = {};
  }

  public componentWillUnmount()
  {
    this.clearAudio();
    this.props.clearAudioPlayerMusicTrack();
  }

  public componentDidUpdate( prevProps: AudioPlayerProps )
  {
    const currentMusic = this.props.currentMusic;
    if ( currentMusic )
    {
      if ( !isEqual( prevProps.currentMusic, currentMusic ) )
      {
        this.startPlayingAudio( currentMusic );
      }
      else
      {
        if ( prevProps.currentMusicPlaying !== this.props.currentMusicPlaying )
        {
          if ( this.props.currentMusicPlaying )
          {
            this.startPlayingAudio( currentMusic );
          }
          else
          {
            this.pausePlayingAudio();
          }
        }
      }
    }
    else
    {
      this.pausePlayingAudio();
    }
  }

  public render()
  {
    return "";
  }

  private startPlayingAudio = ( musicTrack: MusicTrack ) =>
  {
    this.clearAudio();

    const theAudioElement = new Audio( musicTrack.url );
    const startTimeInSeconds = musicTrack.startTimeInSeconds ? musicTrack.startTimeInSeconds : 0;
    theAudioElement.currentTime = startTimeInSeconds;

    if ( theAudioElement )
    {
      theAudioElement.addEventListener( "ended", () =>
      {
        if ( theAudioElement )
        {
          theAudioElement.currentTime = startTimeInSeconds;
        }
      } );
    }

    const playPromise = theAudioElement && theAudioElement.play();
    if ( playPromise )
    {
      this.setState( {
        audioElement: theAudioElement,
        playPromise,
      } );

      playPromise.catch( ( error: any ) =>
      {
        if ( error.name === "NotAllowedError" )
        {
          this.setState( { playPromise: undefined } );
          this.props.setAudioPlayerPlaying( false );
        }
        else
        {
          this.clearAudio();
          this.props.setAudioPlayerPlaying( false );
        }
      } ).finally( () =>
      {
        this.setState( { playPromise: undefined } );
      } );
    }
  }

  private pausePlayingAudio = () =>
  {
    const { audioElement, playPromise } = this.state;
    if ( playPromise )
    {
      playPromise.then( () => audioElement && audioElement.pause() ).catch( ( error ) => console.log( "pause after play failed " + error ) );
    }
    else if ( audioElement )
    {
      audioElement.pause();
    }
  }

  private clearAudio = () =>
  {
    this.pausePlayingAudio();

    this.setState( {
      audioElement: undefined,
      playPromise: undefined,
    } );
  }

}
