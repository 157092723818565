import queryString from "query-string";
import { has, isEmpty } from "lodash";
import { HTTPMethod } from "workbox-routing/utils/constants";
import { store } from "../app/store";
import {
  getUserAuthData,
  isUserLoggedIn,
  updateUID,
  updateUserAuthData,
  updateUserVerificationRequired,
  UserAuthData
} from "../features/user/userSlice";
import { ALKAI_VERSION, ENV_CONFIG } from "../constants";
import { formatISO } from "date-fns";
import { signOutUser } from "../features/user/signOutUser";
import { currentUserBusinessId } from "../features/business/businessSlice";
import { errorAlert, setAlertMessage } from "../features/alert/alertSlice";
import { StatusCodes as HttpStatus } from "http-status-codes";
import Cookies from "universal-cookie";

export const UNAUTHORIZED_ERROR = 'Unauthorized';
export const ABORT_ERROR = 'AbortError';

const ASYNC_REQUEST_POLL_SLEEP_INTERVAL_MS = 2000;
const DEFAULT_ASYNC_REQUEST_POLL_TIMEOUT_MS = 30 * 2000;

const ASYNC_REQUEST_STATUS_PROCESSED = 'processed';
const ASYNC_REQUEST_STATUS_FAILED = 'failed';
type AsyncRequestStatus = typeof ASYNC_REQUEST_STATUS_PROCESSED | typeof ASYNC_REQUEST_STATUS_FAILED;

interface MakeRequestParams
{
  baseUrl: string;
  endPoint: string;
  queryParams?: any;
  methodType: HTTPMethod;
  body?: string | FormData;
  headers?: any;
  includeCredentials?: boolean;
}

interface AsyncRequestAPI
{
  token: string,
  url: string
}

interface AsyncInnerResponseAPI
{
  status_code: number,
  body: string
}

interface AsyncResponseAPI
{
  status: AsyncRequestStatus,
  response: AsyncInnerResponseAPI
}

export function getToApi<R>( endPoint, queryParams = {} ): Promise<R>
{
  return makeAuthenticatedRequest<R>( { baseUrl: ENV_CONFIG.baseUrl, endPoint, methodType: "GET", queryParams } );
}

export function postJsonToApi<R>( endPoint, queryParams = {}, jsonBody?: {} ): Promise<R>
{
  const body = jsonBody ? JSON.stringify( jsonBody ) : undefined;
  return makeAuthenticatedRequest<R>( {
      baseUrl: ENV_CONFIG.baseUrl,
      endPoint,
      methodType: "POST",
      queryParams,
      headers: {
        "Content-Type": "application/json"
      },
      body
    }
  );
}

export async function asyncPostJsonToApi<R>( endPoint, queryParam = {}, jsonBody?: {},
                                             pollTimeout = DEFAULT_ASYNC_REQUEST_POLL_TIMEOUT_MS ): Promise<R>
{
  return postJsonToApi<AsyncRequestAPI>( endPoint, queryParam, jsonBody ).then( ( data ) =>
  {
    return pollForAsyncRequest( data, pollTimeout );
  } )
}

const sleep = ( ms: number ) => new Promise( ( r ) => setTimeout( r, ms ) );

async function pollForAsyncRequest( data: AsyncRequestAPI, timeout: number )
{
  let checkCount = timeout / ASYNC_REQUEST_POLL_SLEEP_INTERVAL_MS;

  while ( checkCount > 0 )
  {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        "X-JOB-AUTHORIZATION": data.token
      }
    };

    const response = await fetch( data.url, requestOptions );

    if ( response.status === HttpStatus.ACCEPTED )
    {
      await sleep( ASYNC_REQUEST_POLL_SLEEP_INTERVAL_MS );
      checkCount--;
    }
    else
    {
      if ( response.status === HttpStatus.OK )
      {
        const responseJson: AsyncResponseAPI = await response.json();

        if ( responseJson.status === 'processed' )
        {
          const innerResponse = responseJson.response;

          if ( innerResponse.status_code === HttpStatus.OK )
          {
            return JSON.parse( innerResponse.body );
          }
        }
      }

      return Promise.reject( "Async request failed." )
    }
  }
  return Promise.reject( "Async Request polling timed out after " + timeout + " milliseconds." )
}

export function postToApi<R>( endPoint, queryParams = {}, body? ): Promise<R>
{
  return makeAuthenticatedRequest<R>( { baseUrl: ENV_CONFIG.baseUrl, endPoint, methodType: "POST", queryParams, body } );
}

export function deleteToApi<R>( endPoint, queryParams = {} ): Promise<R>
{
  return makeAuthenticatedRequest<R>( { baseUrl: ENV_CONFIG.baseUrl, endPoint, methodType: "DELETE", queryParams } );
}

export function getAbortController()
{
  if ( !window.abortController )
  {
    window.abortController = new AbortController();
  }
  return window.abortController;
}

export function clearAbortController()
{
  if ( window.abortController )
  {
    delete window.abortController;
  }
}

export function makeAuthenticatedRequest<R>( makeRequestParams: MakeRequestParams ): Promise<R>
{
  const finalQueryParams = addCommonQueryParams( makeRequestParams.queryParams );

  const headers = makeRequestParams.headers || {};
  const signal = getAbortController().signal

  const requestOptions: RequestInit = {
    method: makeRequestParams.methodType,
    headers: { ...headers, ...getAuthorizationHeader() },
    body: makeRequestParams.body,
    signal
  };

  if ( makeRequestParams.includeCredentials )
  {
    requestOptions.credentials = "include";
  }

  let finalUrl = makeRequestParams.baseUrl;

  if ( !isEmpty( makeRequestParams.endPoint ) )
  {
    finalUrl = finalUrl + "/" + makeRequestParams.endPoint;
  }

  if ( !isEmpty( finalQueryParams ) )
  {
    finalUrl = finalUrl + "?" + railsStringify( finalQueryParams )
  }

  return fetch( finalUrl, requestOptions )
    .then( async ( response ) =>
    {
      if ( response.status === 401 )
      {
        return signOutAndRejectAsUnauthorized();
      }

      const accessToken = response.headers.get( "access-token" );
      const uid = response.headers.get( "uid" );

      try
      {
        const responseClone = await response.clone().json();
        if ( has( responseClone, 'verification_required' ) )
        {
          store.dispatch( updateUserVerificationRequired( responseClone.verification_required ) )
        }

        if ( has( responseClone, 'current_business' ) )
        {
          const currentBusinessId = currentUserBusinessId( store.getState() );
          if ( !!currentBusinessId && responseClone.current_business?.business_id !== currentBusinessId )
          {
            return signOutAndRejectAsUnauthorized();
          }
        }
      }
      catch (error)
      {
        // no need to do anything if not json
        console.info( error )
      }

      if ( accessToken )
      {
        const accessToken = response.headers.get( "access-token" );
        const tokenType = response.headers.get( "token-type" );
        const client = response.headers.get( "client" );
        const expiry = response.headers.get( "expiry" );
        const uid = response.headers.get( "uid" );
        const userAuthData: UserAuthData = {
          tokenType,
          accessToken,
          client,
          expiry,
          uid,
        };

        store.dispatch( updateUserAuthData( userAuthData ) );
      }
      else if ( uid )
      {
        store.dispatch( updateUID( uid ) );
      }

      if ( response.status === 422 )
      {
        const responseClone = await response.clone().json();
        return Promise.reject( responseClone );
      }

      if ( response.status === 500 )
      {
        return Promise.reject( "Internal server error" );
      }

      return handleResponse( response );
    } );
}

function signOutAndRejectAsUnauthorized()
{
  signOutUser( store.dispatch );
  store.dispatch( setAlertMessage( errorAlert( "Your session has expired. Please sign in again.", 'top' ) ) );
  return Promise.reject( UNAUTHORIZED_ERROR );
}

function getAuthorizationHeader()
{
  const storeState = store.getState();
  if ( isUserLoggedIn( storeState ) )
  {
    const userAuthData = getUserAuthData( storeState );

    if ( userAuthData )
    {
      return {
        ...userAuthData,
        'access-token': userAuthData.accessToken,
        'token-type': userAuthData.tokenType,
      }
    }
  }
  return {};
}

function addCommonQueryParams( queryParams )
{
  const cookies = new Cookies( null, { path: '/' } );
  const facebookClickId = cookies.get( '_fbc' );
  const facebookBrowserId = cookies.get( '_fbp' );

  let timeZone: string = ""
  try
  {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  catch (e)
  {
    // ignore
  }
  return {
    ...queryParams,
    client_version: ALKAI_VERSION,
    date_with_timezone: formatISO( new Date() ),
    time_zone: timeZone,
    fb_click_id: facebookClickId,
    fb_browser_id: facebookBrowserId
  }
}

export function handleResponse( response: Response, returnExtendedErrorInfo: boolean = false ): Promise<any>
{
  if ( !response.ok )
  {
    const contentType = response.headers.get( "content-type" );
    if ( contentType && contentType.indexOf( "application/json" ) !== -1 )
    {
      return response.json().then( ( data ) =>
      {
        if ( data.error && data.code )
        {
          return rejectPromise( data, response, returnExtendedErrorInfo );
        }
        if ( data.error )
        {
          return rejectPromise( data.error, response, returnExtendedErrorInfo );
        }
        if ( data.errors )
        {
          return rejectPromise( data.errors, response, returnExtendedErrorInfo );
        }
        return rejectPromise( data, response, returnExtendedErrorInfo );
      } );
    }
    else
    {
      return response.text().then( ( text ) =>
      {
        return rejectPromise( text || response.statusText, response, returnExtendedErrorInfo );
      } );
    }
  }

  if ( response.status === 204 )
  {
    return Promise.resolve( undefined );
  }

  return response.json();
}

function rejectPromise( reason: any, response: Response, returnExtendedErrorInfo: boolean )
{
  return Promise.reject( reason );
}

function railsStringify( queryParams: any )
{
  return queryString.stringify( queryParams, { arrayFormat: "bracket" } );
}
