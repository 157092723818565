import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getBusinessName } from "../business/businessSlice";
import React from "react";
import { Stack, TextField } from "@mui/material";

export function UserSettingsBusinessDetails()
{
  const businessName = useSelector( ( state: RootState ) => getBusinessName( state ) ) || "";

  return (
    <Stack spacing={10}>
      <Stack direction="row" spacing={10}>
        <TextField label="Business Name"
                   variant="outlined"
                   disabled
                   sx={{ width: "75%" }}
                   value={businessName}/>
      </Stack>
    </Stack>
  );
}
