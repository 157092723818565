import { businessServices, FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../../services/business.services";
import React, { useContext } from "react";
import { SocialShareStatusBar } from "./socialShareStatusBar";
import { RefreshFacebookButton } from "../buttons/refreshFacebookButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SocialShareStatusBarTypography } from "./socialShareStatusBarTypography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface SocialNetworkAccountRefreshBarProps
{
  socialNetworkAccount: SocialNetworkAccountAPI;
}

export function SocialNetworkAccountRefreshBar( props: SocialNetworkAccountRefreshBarProps )
{
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );

  function handleRefresh()
  {
    console.log( "Non facebook refresh bar button clicked" );
  }

  function handleFacebookRefreshComplete()
  {
    console.log( "Facebook refresh complete" );
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
    {
      socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
    } );
  }

  if ( !socialNetworkAccountHelper.needsRefresh( props.socialNetworkAccount ) )
  {
    return null;
  }
  if ( props.socialNetworkAccount.account_type === FACEBOOK_PAGE || props.socialNetworkAccount.account_type === FACEBOOK_INSTAGRAM )
  {
    const tooltipMessage = <Stack>
      <Typography variant={"caption"} sx={{fontWeight: 800}}>Refresh Connection to Publish Posts</Typography>
      <Typography variant={"caption"}>Connections for your social profiles have expired. A post can't be published to a profile with an expired connection. Before refreshing, ensure popup blockers are turned off.</Typography>
    </Stack>
    const accountType = socialNetworkAccountHelper.getDisplayName( props.socialNetworkAccount )
    const content = <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <WarningAmberIcon sx={{ color: "white" }}/>
      <SocialShareStatusBarTypography>{accountType} connection expired </SocialShareStatusBarTypography>
      <Tooltip
        title={tooltipMessage}
        enterTouchDelay={50}>
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            <HelpOutlineIcon sx={{ color: "white", height: "15px", width: "15px" }}/>
          </Box>
      </Tooltip>
    </Stack>
    return (<SocialShareStatusBar content={content}
                                  rightButton={<RefreshFacebookButton handleRefreshComplete={handleFacebookRefreshComplete}/>}/>);
  }
  return (<SocialShareStatusBar content={props.socialNetworkAccount.account_type} buttonText="Refresh" onButtonClick={handleRefresh}/>);
}