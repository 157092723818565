import * as React from 'react';
import { useEffect } from 'react';
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { Box, Typography } from "@mui/material";
import { MAX_NUMBER_OF_LOGOS, MAX_NUMBER_OF_LOGOS_ERROR, MediaPickingMode } from "../constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalMediaUploadDialog from './localMediaUploadDialog';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { MediaAssetAPI } from "../../services/business.services";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import AlertBanner from "../alert/alertBanner";
import { clearAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";

interface LogoPickerProps extends WithFullScreenDialogProps
{
  title: string;
  postIdea?: PostIdeaDataAPI;
  processSelectedMediaAssets: ( mediaAssets: MediaAssetAPI[] ) => Promise<void>;
  mediaPickingMode: MediaPickingMode;
  currentMediaUrls: string[];
}

function LogoPicker( props: LogoPickerProps )
{
  const dispatch = useDispatch();

  useEffect( () =>
  {
    return () =>
    {
      dispatch( clearAlertMessage() );
    }
  }, [] );

  async function handleChooseMediaAssets( mediaAssets: MediaAssetAPI[] )
  {
    if ( !!mediaAssets && !!props.processSelectedMediaAssets )
    {
      if ( props.processSelectedMediaAssets )
      {
        await props.processSelectedMediaAssets( mediaAssets );
      }
    }
  }

  const handleBackClick = () =>
  {
    if ( props.handleClose )
    {
      props.handleClose();
    }
  }

  return (
    <FullPageMobileContainer>
      <FullPageMobileHeader sx={{ position: "relative" }}>
        <Box textAlign="center" sx={{ width: "100%" }}>
          <ArrowBackIcon onClick={handleBackClick} sx={{ position: "absolute", top: "calc(50% - 12px)", left: 20 }}/>
          <Typography variant="h6">{props.title}</Typography>
        </Box>
      </FullPageMobileHeader>
      <FullPageMobileContent>
        <LocalMediaUploadDialog id="localMediaUpload"
                                postIdea={props.postIdea}
                                keepMounted={false}
                                currentMediaUrls={props.currentMediaUrls}
                                handleChooseMediaAssets={handleChooseMediaAssets}
                                confirmationButtonText="Select logo"
                                logoPicking={true}
                                maxMediaCount={MAX_NUMBER_OF_LOGOS}
                                maxMediaCountError={MAX_NUMBER_OF_LOGOS_ERROR}/>
      </FullPageMobileContent>
      <AlertBanner/>
    </FullPageMobileContainer>
  )
}

export const LogoPickerFullScreen = withFullScreenDialog( LogoPicker )
