import React from 'react'
import { Button } from "@mui/material";
import { authenticationServices } from "../../services/authentication.services";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../constants";
import { signOutUser } from "../user/signOutUser";
import { eventTracker } from "../../helpers/eventTracker";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";

export function SignOutButton()
{
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  const handleSignOut = async () =>
  {
    dispatch( setApplicationLoadingState( true ) );

    eventTracker.logSettingsSignOutClicked();
    setSearchParams( {} );
    await authenticationServices.signOut().finally( () =>
    {
      signOutUser( dispatch );
      dispatch( setApplicationLoadingState( false ) );
      navigate( ROUTES.ROOT );
    } );
  }

  return (
    <Button aria-label="sign out button" variant="outlined" color="error" onClick={handleSignOut}>Sign out</Button>
  )
}
