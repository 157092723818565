import * as React from 'react';
import { useEffect, useState } from 'react';
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import StockMediaPickerContainer from "../stock_media/StockMediaPicker.container";
import StockMediaConfirmationDialog from "./stockMediaConfirmationDialog";
import { Stack, Typography } from "@mui/material";
import {
  MAX_NUMBER_OF_MEDIA,
  MAX_NUMBER_OF_MEDIA_ERROR,
  MEDIA_ASSET_SOURCE_WEBSITE,
  MEDIA_TAB_ID_GENERATE,
  MEDIA_TAB_ID_SEARCH,
  MEDIA_TAB_ID_UPLOAD,
  MEDIA_TAB_ID_WEBSITE,
  MediaAssetSource,
  MediaPickingMode,
  STOCK_MEDIA_SOURCE_MEDIA_ASSET,
  USER_UPLOAD_SOURCE_MEDIA_ASSET
} from "../constants";
import { CustomTabPanel } from "../editing/customTabPanel";
import StableDiffusionImageGeneratorDialog from "./stableDiffusionImageGeneratorDialog";
import LocalMediaUploadDialog from './localMediaUploadDialog';
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { FullPageMobileContent } from "../layout/fullPageMobileContent";
import { businessServices, MediaAssetAPI, MediaAssetTrackingDataAPI } from "../../services/business.services";
import { eventTracker } from "../../helpers/eventTracker";
import { ExternalMediaData, searchResultsCleared } from "../stock_media/stockMediaSlice";
import { useDispatch, useSelector } from "react-redux";
import WebsiteMediaTab from "./websiteMediaTab";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { setAlertMessage, successAlert } from "../alert/alertSlice";
import { FullScreenDialogWallWithBlurredBackground } from "../planner/fullScreenDialogWallWithBlurredBackground";
import { PlanModalBodyText } from "../planner/planModalBodyText";
import { RootState } from "../../app/store";
import { getHasSeenMediaLibraryAnnouncementDialog } from "../business/businessSlice";
import { mediaUtils } from "../../helpers/mediaUtils";
import { UrlUtils } from "../utils/urlUtils";
import { MediaAssetData, mediaAssetServices } from "../../services/mediaAssetServices";
import ProgressOverlay from "../loadingIndicator/progressOverlay";

interface MediaPickerProps extends WithFullScreenDialogProps
{
  title: string;
  postIdea?: PostIdeaDataAPI;
  defaultStockMediaSearchTerms?: string[];
  defaultDescriptionOfScene?: string[];
  processSelectedMediaUrl?: ( selectedUrl: string, mediaSource: MediaAssetSource,
                              onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, selectedUrl: string,
                                                    mediaSource: MediaAssetSource ) => void ) => Promise<void>;
  processSelectedMediaAssets?: ( mediaAssets: MediaAssetAPI[],
                                 mediaAssetSource: MediaAssetSource,
                                 closeAddAfterHandlingSection: boolean,
                                 onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                       mediaAssetSource: MediaAssetSource ) => void ) => Promise<void>;
  mediaPickingMode: MediaPickingMode;
  currentMediaUrls?: string[];
  openedFromMediaLibraryTab?: boolean;
}

export function MediaPicker( props: MediaPickerProps )
{
  const dispatch = useDispatch();

  const [selectedStockMediaData, setSelectedStockMediaData] = useState<ExternalMediaData | undefined>( undefined );
  const [value, setValue] = useState( getStartingTabId() );
  const suggestedSearchTerm = props.defaultStockMediaSearchTerms || [];
  const defaultDescription = props.postIdea?.media_description || "";
  const [showMediaLibraryAnnouncementDialog, setShowMediaLibraryAnnouncementDialog] = useState<boolean>( false );
  const hasSeenMediaLibraryAnnouncementDialog = useSelector( ( state: RootState ) => getHasSeenMediaLibraryAnnouncementDialog( state ) );
  const [addingMedia, setAddingMedia] = useState<boolean>( false );
  const [processingSelectedMedia, setProcessingSelectedMedia] = useState<boolean>( false );

  useEffect( () =>
  {
    dispatch( searchResultsCleared() );
  }, [] );

  useEffect( () =>
  {
    if ( !hasSeenMediaLibraryAnnouncementDialog )
    {
      setShowMediaLibraryAnnouncementDialog( true );
    }
  }, [] );

  function getStartingTabId()
  {
    return MEDIA_TAB_ID_UPLOAD;
  }

  async function handleChooseStableDiffusionUrl( selectedUrl?: string, mediaSource?: MediaAssetSource )
  {
    setSelectedStockMediaData( undefined );

    if ( !!selectedUrl && !!mediaSource && !!props.processSelectedMediaUrl )
    {
      await props.processSelectedMediaUrl( selectedUrl, mediaSource, onAddSuccessForStableDiffusion );
    }
  }

  function onAddSuccessForStableDiffusion( postIdea: PostIdeaDataAPI | undefined, selectedUrl: string, mediaSource: MediaAssetSource )
  {
    const hasVideo = new UrlUtils().isVideoUrl( selectedUrl );
    const hasPhoto = !hasVideo;

    if ( !!postIdea )
    {
      eventTracker.logEditPostMediaAdded( postIdea, mediaSource, 1, hasVideo, !hasPhoto );
    }
    const addLocation = props.openedFromMediaLibraryTab ? "library" : "post";
    dispatch( setAlertMessage( successAlert( `Media added to ${addLocation}`, 'top' ) ) );
  }

  function showStockMediaConfirmationDialog( stockMediaData: ExternalMediaData )
  {
    setSelectedStockMediaData( stockMediaData );
  }

  function bestStockUrl( stockMediaData?: ExternalMediaData ): string | undefined
  {
    let urlToUse = stockMediaData?.full_url
    if ( !!stockMediaData?.preview_video_url )
    {
      urlToUse = stockMediaData?.preview_video_url;
    }
    return urlToUse;
  }

  async function handleChooseStockMediaData( selectedStockMedia?: ExternalMediaData, mediaSource?: MediaAssetSource )
  {
    if ( !!selectedStockMedia )
    {
      setAddingMedia( true );
      const selectedUrl = bestStockUrl( selectedStockMedia );
      if ( !!selectedUrl && !!mediaSource && !!props.processSelectedMediaUrl )
      {
        const data = await fetch( selectedUrl );

        const blob = await data.blob();

        const fileName = new UrlUtils().getFilenameFromUrlString( selectedUrl );
        const mediaAsset: MediaAssetData = {
          fileName: fileName,
          file: blob,
          fileType: mediaUtils.getMediaAssetFileType( fileName ),
          isLogo: false,
          trackingData: selectedStockMedia as MediaAssetTrackingDataAPI
        }

        const mediaAssets = await mediaAssetServices.uploadAssets( [mediaAsset], STOCK_MEDIA_SOURCE_MEDIA_ASSET );

        await chooseMediaAndMaybeClose( mediaAssets, STOCK_MEDIA_SOURCE_MEDIA_ASSET, false, onAddSuccessForStock );

        setAddingMedia( false );
        setSelectedStockMediaData( undefined );
      }
    }
    else
    {
      setSelectedStockMediaData( undefined );
    }
  }

  function onAddSuccessForStock( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource )
  {
    logEditPostMediaAdded( postIdea, mediaAssets, mediaAssetSource );
    const addLocation = props.openedFromMediaLibraryTab ? "library" : "post";
    dispatch( setAlertMessage( successAlert( `Media added to ${addLocation}`, 'top' ) ) );
  }

  async function handleChooseMediaAssetsFromUploadTab( mediaAssets: MediaAssetAPI[] )
  {
    await chooseMediaAndMaybeClose( mediaAssets, USER_UPLOAD_SOURCE_MEDIA_ASSET, true, logEditPostMediaAdded );
  }

  async function handleChooseMediaAssetsFromWebsiteTab( mediaAssets: MediaAssetAPI[] )
  {
    await chooseMediaAndMaybeClose( mediaAssets, MEDIA_ASSET_SOURCE_WEBSITE, true, logEditPostMediaAdded );
  }

  function logEditPostMediaAdded( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource )
  {
    if ( !!postIdea )
    {
      const hasVideo = !!mediaAssets.find( ( asset ) => asset.is_video );
      const hasPhoto = !!mediaAssets.find( ( asset ) => !asset.is_video );
      eventTracker.logEditPostMediaAdded( postIdea, mediaAssetSource, mediaAssets.length, hasVideo, hasPhoto );
    }
  }

  async function chooseMediaAndMaybeClose( mediaAssets: MediaAssetAPI[], mediaAssetSource: MediaAssetSource, closeAddAfterHandlingSection: boolean,
                                           onAddMediaSuccess?: ( postIdea: PostIdeaDataAPI | undefined, mediaAssets: MediaAssetAPI[],
                                                                 mediaAssetSource: MediaAssetSource ) => void )
  {
    if ( !!mediaAssets && !!props.processSelectedMediaAssets )
    {
      if ( props.processSelectedMediaAssets )
      {
        setProcessingSelectedMedia( true );
        await props.processSelectedMediaAssets( mediaAssets, mediaAssetSource, closeAddAfterHandlingSection, onAddMediaSuccess )
          .finally( () => setProcessingSelectedMedia( false ) );
      }
    }
  }

  const valueToSubtitleMap = ( value: number ) =>
  {
    switch ( value )
    {
      case MEDIA_TAB_ID_WEBSITE:
        return "From web";
      case MEDIA_TAB_ID_SEARCH:
        return "Stock";
      case MEDIA_TAB_ID_GENERATE:
        return "Generate with AI";
      default:
        return "";
    }
  }

  const handleChangeTabs = ( tabId: number ) =>
  {
    if ( tabId === 0 )
    {
      handleBackClicked();
    }
    else
    {
      setValue( tabId );
    }

    switch ( tabId )
    {
      case MEDIA_TAB_ID_WEBSITE:
        handleWebsiteClicked();
        break;
      case MEDIA_TAB_ID_SEARCH:
        handleSearchClicked();
        break;
      case MEDIA_TAB_ID_GENERATE:
        handleGenerateClicked();
        break;
    }
  };

  const handleBackClicked = () =>
  {
    if ( props.openedFromMediaLibraryTab )
    {
      setValue( MEDIA_TAB_ID_UPLOAD );
    }
    else
    {
      if ( props.handleClose )
      {
        props.handleClose();
      }
    }
  }

  const handleWebsiteClicked = () =>
  {
    if ( props.openedFromMediaLibraryTab )
    {
      eventTracker.logMediaLibraryWebsiteClicked();
    }
    else if ( !!props.postIdea )
    {
      eventTracker.logEditPostMediaWebsiteClicked( props.postIdea );
    }
  }

  const handleSearchClicked = () =>
  {
    if ( props.openedFromMediaLibraryTab )
    {
      eventTracker.logMediaLibraryStockClicked();
    }
    else if ( !!props.postIdea )
    {
      eventTracker.logEditPostMediaStockClicked( props.postIdea );
    }
  }

  const handleGenerateClicked = () =>
  {
    if ( props.openedFromMediaLibraryTab )
    {
      eventTracker.logMediaLibraryAiGenerateClicked();
    }
    else if ( !!props.postIdea )
    {
      eventTracker.logEditPostMediaAiGenerateClicked( props.postIdea );
    }
  }

  const getTextForMediaPickingMode = () =>
  {
    switch ( props.mediaPickingMode )
    {
      case MediaPickingMode.Add:
        return "Add selected";
      case MediaPickingMode.Overwrite:
        return "Add selected";
      default:
        return "Add Media";
    }
  }

  function shouldShowBackButton()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      return value !== MEDIA_TAB_ID_UPLOAD
    }
    return true;
  }

  function getHeaderPadding()
  {
    if ( props.openedFromMediaLibraryTab && value !== MEDIA_TAB_ID_UPLOAD )
    {
      return 0;
    }
    return 10;
  }

  function getFullPageModalDialogProps()
  {
    return {
      loading: false,
      header: "Welcome to the Media Library",
      body: <Stack>

        <PlanModalBodyText sx={{ textAlign: "left" }}>This is your collection of images and videos for creating posts. Add media that reflects your
                                                      business, products, and community events.<br/><br/>You can upload media directly, find stock
                                                      images and videos, capture images from webpages, or
                                                      create with AI.</PlanModalBodyText>
      </Stack>,
      primaryCTAText: "Ok",
      handleCloseXClicked: closeMediaLibraryAnnouncementDialog,
      handlePrimaryCTAClicked: closeMediaLibraryAnnouncementDialog
    }
  }

  function closeMediaLibraryAnnouncementDialog()
  {
    setShowMediaLibraryAnnouncementDialog( false )
    businessServices.updateBusinessClientUiFlagsJson( { has_seen_media_library_announcement_dialog: true } )
  }

  return (
    <FullPageMobileContainer>
      <FullPageMobileHeader sx={{ px: getHeaderPadding() }}>
        <Stack>
          <Stack direction="row" alignItems="center">
            {shouldShowBackButton() && <IconButton onClick={handleBackClicked}>
              <ArrowBackIcon/>
            </IconButton>}
            <Typography variant="h6" sx={{ lineHeight: "40px" }}>{props.title}</Typography>
          </Stack>
          <Typography variant="subtitle2" sx={{ ml: shouldShowBackButton() ? 20 : 0 }}> {valueToSubtitleMap( value )} </Typography>
        </Stack>
      </FullPageMobileHeader>
      <FullPageMobileContent>
        <CustomTabPanel value={value} index={MEDIA_TAB_ID_UPLOAD}>
          <LocalMediaUploadDialog id="localMediaUpload"
                                  postIdea={props.postIdea}
                                  keepMounted={false}
                                  currentMediaUrls={props.currentMediaUrls}
                                  handleChooseMediaAssets={handleChooseMediaAssetsFromUploadTab}
                                  confirmationButtonText={getTextForMediaPickingMode()}
                                  logoPicking={false}
                                  maxMediaCount={MAX_NUMBER_OF_MEDIA}
                                  maxMediaCountError={MAX_NUMBER_OF_MEDIA_ERROR}
                                  previewMediaInsteadOfSelect={props.openedFromMediaLibraryTab}
                                  openedFromMediaLibraryTab={props.openedFromMediaLibraryTab}
                                  handleChangeTabs={handleChangeTabs}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={MEDIA_TAB_ID_WEBSITE}>
          <WebsiteMediaTab postIdea={props.postIdea}
                           currentMediaUrls={props.currentMediaUrls}
                           openedFromMediaLibraryTab={props.openedFromMediaLibraryTab}
                           handleChooseMediaAssets={handleChooseMediaAssetsFromWebsiteTab}
                           handleBackClicked={handleBackClicked}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={MEDIA_TAB_ID_SEARCH}>
          <StockMediaPickerContainer suggestedSearchTerms={suggestedSearchTerm}
                                     showStockMediaConfirmationDialog={showStockMediaConfirmationDialog}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={MEDIA_TAB_ID_GENERATE}>
          <StableDiffusionImageGeneratorDialog id={"stableDiffusionGenerator"}
                                               keepMounted={false}
                                               onClose={handleChooseStableDiffusionUrl}
                                               search_term={defaultDescription}
                                               openedFromMediaLibraryTab={props.openedFromMediaLibraryTab}/>
        </CustomTabPanel>
        {showMediaLibraryAnnouncementDialog && <FullScreenDialogWallWithBlurredBackground {...getFullPageModalDialogProps()}/>}
        <StockMediaConfirmationDialog id="stockMediaConfirmationDialog"
                                      keepMounted
                                      stockMediaLoading={addingMedia}
                                      value={selectedStockMediaData}
                                      open={!!selectedStockMediaData}
                                      onClose={handleChooseStockMediaData}
                                      mediaUrl={bestStockUrl( selectedStockMediaData ) || ""}/>
        {processingSelectedMedia && <ProgressOverlay/>}
      </FullPageMobileContent>
    </FullPageMobileContainer>
  )
}

export const MediaPickerFullScreenDialog = withFullScreenDialog( MediaPicker )
