import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";
import { BusinessMediaLibraryApi, MediaAssetAPI } from "../../services/business.services";

export interface UIState
{
  audioPlayerTrack?: MusicTrack,
  audioPlayerPlaying?: boolean,
  conversation: ConversationUIState,
  media_library: MediaLibraryState,
  upsellShown: boolean;
  paymentDialogOpen: boolean;
  progressState?: ProgressState;
  hasSeenExtendedPlan?: boolean;
  plan: PlanUIState;
  hasSeenPostStrategyQuiz?: boolean;
}

export interface MusicTrack
{
  url: string;
  startTimeInSeconds?: number;
}

export interface MediaLibraryState
{
  media_assets: MediaAssetAPI[];
  next_page?: number
}

interface ConversationUIState
{
  yield_to_drawer: boolean;
  last_yield_to_drawer_time: number;
  pending_draft_post_idea_id?: string;
}

interface PlanUIState
{
  lastDateClicked?: string;
  lastPostIdeaEdited?: string;
}

export interface ProgressState
{
  progress?: number;
  message?: string;
}

const initialState: UIState = {
  audioPlayerTrack: undefined,
  audioPlayerPlaying: false,
  conversation: {
    yield_to_drawer: false,
    last_yield_to_drawer_time: 0,
  },
  media_library: {
    media_assets: [],
  },
  upsellShown: false,
  paymentDialogOpen: false,
  plan: {},
};

export const uiSlice = createSlice( {
  name: 'ui',
  initialState,
  reducers: {
    setAudioPlayerTrack: ( state, action: PayloadAction<MusicTrack | undefined> ) =>
    {
      return {
        ...state,
        audioPlayerTrack: action.payload,
      };
    },
    setConversationYieldToDrawer: ( state, action ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          yield_to_drawer: action.payload,
        }
      }
    },
    setConversationLastYieldToDrawerTime: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          last_yield_to_drawer_time: action.payload,
        }
      }
    },
    setAudioPlayerPlaying: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        audioPlayerPlaying: action.payload,
      };
    },
    clearAudioPlayerTrack: ( state ) =>
    {
      return {
        ...state,
        audioPlayerTrack: undefined,
      };
    },
    setPendingDraftPostIdeaId: ( state, action: PayloadAction<string> ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          pending_draft_post_idea_id: action.payload
        }
      }
    },
    clearPendingDraftPostIdeaId: ( state ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          pending_draft_post_idea_id: undefined
        }
      }
    },
    clearUI: () =>
    {
      return {
        ...initialState
      };
    },
    addToMediaLibrary: ( state, action: PayloadAction<BusinessMediaLibraryApi> ) =>
    {
      const existingMediaLibrary = state.media_library || {
        media_assets: [],
        next_page: undefined
      };
      return {
        ...state,
        media_library: {
          ...existingMediaLibrary,
          media_assets: [...existingMediaLibrary.media_assets, ...action.payload.media_assets],
          next_page: action.payload.next_page,
        }
      }
    },
    clearMediaLibrary: ( state ) =>
    {
      return {
        ...state,
        media_library: {
          media_assets: [],
          next_page: undefined
        }
      }
    },
    setUpsellShown: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        upsellShown: action.payload,
      };
    },
    setPaymentDialogOpen: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        paymentDialogOpen: action.payload,
      };
    },
    setProgressState: ( state, action: PayloadAction<ProgressState> ) =>
    {
      const existingProgressState = state.progressState;
      return {
        ...state,
        progressState: {
          ...existingProgressState,
          ...action.payload,
        }
      };
    },
    clearProgressState: ( state ) =>
    {
      return {
        ...state,
        progressState: undefined,
      };
    },
    setPlanLastDateClicked: ( state, action: PayloadAction<string> ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastDateClicked: action.payload
        }
      }
    },
    clearPlanLastDateClicked: ( state ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastDateClicked: undefined
        }
      }
    },
    setPlanLastPostIdeaEdited: ( state, action: PayloadAction<string | undefined> ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastPostIdeaEdited: action.payload
        }
      }
    },
    clearPlanLastPostIdeaEdited: ( state ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastPostIdeaEdited: undefined
        }
      }
    },
    setHasSeenPostStrategyQuiz: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        hasSeenPostStrategyQuiz: action.payload,
      };
    },
  }
} )

export function getCurrentMusicTrack( state: RootState )
{
  return state.ui.audioPlayerTrack;
}

export function getCurrentMusicTrackPlaying( state: RootState )
{
  return state.ui.audioPlayerPlaying;
}

export function getShouldConversationYieldToDrawer( state: RootState )
{
  return state.ui.conversation.yield_to_drawer;
}

export function getConversationLastYieldToDrawer( state: RootState )
{
  return state.ui.conversation.last_yield_to_drawer_time;
}

export function getPendingDraftPostIdeaId( state: RootState )
{
  return state.ui.conversation.pending_draft_post_idea_id
}

export function getProgress( state: RootState )
{
  return state.ui.progressState;
}

export const getMediaAssets = ( state ) => state.ui.media_library.media_assets;
export const getMediaLibraryNextPage = ( state ) => state.ui.media_library.next_page;
export const getUpsellShown = ( state ) => state.ui.upsellShown;
export const getPaymentDialogOpen = ( state ) => state.ui.paymentDialogOpen;
export const getPlanLastDateClicked = ( state ) => state.ui.plan.lastDateClicked;
export const getPlanLastPostIdeaEdited = ( state ) => state.ui.plan.lastPostIdeaEdited;
export const hasSeenPostStrategyQuiz = ( state ) =>
{
  if ( !!state.ui.hasSeenPostStrategyQuiz )
  {
    return state.ui.hasSeenPostStrategyQuiz;
  }

  return false;
}

export const {
  setAudioPlayerTrack,
  setConversationYieldToDrawer,
  setConversationLastYieldToDrawerTime,
  clearAudioPlayerTrack,
  setAudioPlayerPlaying,
  setPendingDraftPostIdeaId,
  clearPendingDraftPostIdeaId,
  clearUI,
  addToMediaLibrary,
  clearMediaLibrary,
  setUpsellShown,
  setPaymentDialogOpen,
  setProgressState,
  clearProgressState,
  setPlanLastDateClicked,
  clearPlanLastDateClicked,
  setPlanLastPostIdeaEdited,
  clearPlanLastPostIdeaEdited,
  setHasSeenPostStrategyQuiz,
} = uiSlice.actions

export default uiSlice.reducer
