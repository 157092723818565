import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { hasEmail, hasPhoneNumber } from "../user/userSlice";

export interface NotificationSubscriptionToggleProps
{
  isToggleOn: boolean;
  onChange: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
}

export function NotificationSubscriptionToggle( props: NotificationSubscriptionToggleProps )
{
  const hasPhoneChannel = useSelector( ( state: RootState ) => hasPhoneNumber( state ) );
  const hasEmailChannel = useSelector( ( state: RootState ) => hasEmail( state ) );

  function getSubscribedNotificationsLabel()
  {
    if ( hasPhoneChannel )
    {
      return "Receive text notifications";
    }
    else if ( hasEmailChannel )
    {
      return "Receive email notifications";
    }
    return "Receive notifications";
  }

  return <Stack direction="row" alignItems="center" sx={{ pb: 15 }}>
    <Typography variant="subtitle1">{getSubscribedNotificationsLabel()}</Typography>
    <Switch checked={props.isToggleOn} onChange={props.onChange}/>
  </Stack>
}