interface EnvConfig
{
  baseUrl: string;
  cdnBaseUrl: string;
  facebookAppId: string;
  sentryConfig: {};
  amplitudeKey: string;
  facebookPixelId: string;
  stripePublicKey: string;
  apptimizeKey: string;
  googleTagId: string;
}

export const ALKI_ENV = process.env.REACT_APP_ALKI_ENV || "development";
const ALKI_SENTRY_DSN = process.env.REACT_APP_ALKI_SENTRY_DSN;
const ALKAI_STRIPE_PUBLIC_KEY = process.env.REACT_APP_ALKAI_STRIPE_PUBLIC_KEY || "";
const IP_ADDRESS = process.env.REACT_APP_IP_ADDRESS || "localhost";
const ALKAI_GTAG_ID = process.env.REACT_APP_ALKAI_GTAG_ID || "";

export const ALKAI_VERSION = process.env.REACT_APP_ALKAI_VERSION || IP_ADDRESS + "-dev";

export const IS_PROD = ALKI_ENV === "production";
export const ALKAI_CORE_ASSISTANT_TYPE = "core";

export const IS_STAGING = ALKI_ENV === "staging";
export const IS_DEVELOPMENT = !(IS_PROD || IS_STAGING);
export const ENABLE_USAGE_TRACKING = IS_PROD || IS_STAGING;
export const ENABLE_EVENT_CONSOLE_LOGGING = IS_DEVELOPMENT;
export const ENABLE_APPTIMIZE = IS_PROD || IS_STAGING || IS_DEVELOPMENT;
export const APPTIMIZE_INITIALIZATION_MAX_WAIT_MILLIS = 8000;
export const ENABLE_APPTIMIZE_ERROR_CONSOLE_LOGGING = IS_DEVELOPMENT;
export const ENABLE_ERROR_REPORTING = true;

function getEnvConfiguration()
{
  switch ( ALKI_ENV )
  {
    case "production":
      return productionConfig;
    default:
      return devConfig;
  }
}

const devConfig: EnvConfig = {
  baseUrl: `http://${IP_ADDRESS}:8086`,
  cdnBaseUrl: `http://${IP_ADDRESS}:3031`,
  facebookAppId: "1718789238375482",
  sentryConfig: {
    dsn: ALKI_SENTRY_DSN,
    release: "b80cd78ac9d7ecae89d74d816605e4cab34a13b1",
    environment: 'development',
    integrations: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  amplitudeKey: "d89d2929fda13fdae0beb38846bd6401",
  facebookPixelId: "372819025085371",
  stripePublicKey: ALKAI_STRIPE_PUBLIC_KEY,
  apptimizeKey: "CbCjGLUUhxQkDsTjL3JFRLvChBmWD28",
  googleTagId: ALKAI_GTAG_ID,
};

const productionConfig: EnvConfig = {
  baseUrl: "https://api.heyalkai.com",
  cdnBaseUrl: "https://d3m08whpn44sr0.cloudfront.net",
  facebookAppId: "1629709950616745",
  sentryConfig: {
    dsn: ALKI_SENTRY_DSN,
    release: ALKAI_VERSION,
    environment: 'production',
    integrations: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
  amplitudeKey: "a0eb9eb4d876976342d20b20ef8de1f3",
  facebookPixelId: "372819025085371",
  stripePublicKey: ALKAI_STRIPE_PUBLIC_KEY,
  apptimizeKey: "D69J6NYCdWEYJZg9SUCtKWk4ThyRHbA",
  googleTagId: ALKAI_GTAG_ID,
};

export const ENV_CONFIG: EnvConfig = getEnvConfiguration();

export function getFacebookAppId()
{
  return ENV_CONFIG.facebookAppId;
}

export function getFacebookPixelId()
{
  return ENV_CONFIG.facebookPixelId;
}

export function getGoogleTagId()
{
  return ENV_CONFIG.googleTagId;
}
