import * as React from 'react';
import { Box, Typography } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import { MediaAssetAPI } from "../../services/business.services";
import { findIndex, isUndefined, merge } from "lodash";
import VideocamIcon from '@mui/icons-material/Videocam';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import TransparentGridTile from "../../assets/transparent-grid.png";
import { UrlUtils } from "../utils/urlUtils";

export interface UserMediaLibraryItemProps
{
  mediaAsset: MediaAssetAPI;
  selectedMediaAssets: MediaAssetAPI[];
  handleMediaClicked: ( mediaAsset: MediaAssetAPI ) => void;
  mediaAssetNumberingOffset?: number;
  showDelete: boolean;
  deleteMediaClicked?: ( mediaAsset: MediaAssetAPI ) => void;
  renderForLogoPicker?: boolean;
}

function UserMediaLibraryItem( props: UserMediaLibraryItemProps )
{
  const foundIndex = findIndex( props.selectedMediaAssets, ( mediaAsset ) => mediaAsset.id === props.mediaAsset.id );
  const isSelected = foundIndex > -1;

  const makeEndItem = () =>
  {
    return <Box>
      {makeCornerLabel()}
      {makeDeleteButton()}
      {makeVideoIndicator()}
    </Box>
  }

  const makeCornerLabel = () =>
  {
    if ( isSelected && !isUndefined( props.mediaAssetNumberingOffset ) )
    {
      return (<Box sx={{ position: "absolute", top: 0, right: 0, p: 5, background: "white" }}>
          <Typography variant={"subtitle2"}>{foundIndex + 1 + props.mediaAssetNumberingOffset}</Typography>
        </Box>
      );
    }
    return null;
  }

  const makeDeleteButton = () =>
  {
    if ( props.showDelete && !!props.deleteMediaClicked )
    {
      return (<Box sx={{ position: "absolute", bottom: 0, left: 0, background: "rgba(0,0,0,.4)" }}>
          <IconButton onClick={handleDeleteClicked} sx={{ padding: 0, color: "white" }}><DeleteIcon/></IconButton>
        </Box>
      );
    }
    return null;
  }

  const handleDeleteClicked = ( e: React.MouseEvent ) =>
  {
    e.stopPropagation();
    if ( !!props.deleteMediaClicked )
    {
      props.deleteMediaClicked( props.mediaAsset );
    }
  }

  const makeVideoIndicator = () =>
  {
    if ( props.mediaAsset.is_video )
    {
      return (
        <Box sx={{ position: "absolute", bottom: 0, right: 0, background: "rgba(0,0,0,.4)", display: "flex" }}>
          <VideocamIcon sx={{ padding: 0, color: "white" }}/>
        </Box>);
    }
    return null;
  }

  const selectedSxProps = () =>
  {
    if ( isSelected )
    {
      return {
        outline: "4px solid",
        outlineColor: ( theme ) => theme.palette.primary.main,
      }
    }
    return {}
  }

  const handleMediaClicked = ( e ) =>
  {
    props.handleMediaClicked( props.mediaAsset );
  }

  function getAspectRatioStyling()
  {
    if ( props.renderForLogoPicker )
    {
      return 1;
    }
    return (9 / 16).toFixed( 2 );
  }

  function getObjectFit()
  {
    if ( props.renderForLogoPicker )
    {
      return "contain";
    }
    return "cover";
  }

  const background = mediaPotentiallyHasTransparency() ? `url(${TransparentGridTile}) repeat` : "white";

  function mediaPotentiallyHasTransparency()
  {
    return new UrlUtils().isFileTypeThatSupportsTransparency( props.mediaAsset?.url )
  }

  function getPadding()
  {
    if ( props.renderForLogoPicker )
    {
      return 4;
    }
    return 0;
  }

  return (
    <MediaItem
      // @ts-ignore
      additionalStyle={{ "--aspect-ratio": getAspectRatioStyling(), width: "107px" }}
      sxProps={merge( {
        background: background,
        "& .mediaListItem": { height: "100%", p: getPadding() },
        "& .mediaListItem.MuiImageListItem-img": { objectFit: getObjectFit() },
        "& .mediaListItem.mediaListItemVideo": {
          width: "100%",
          objectFit: "cover"
        }
      }, selectedSxProps() )}
      key={props.mediaAsset.id}
      isVideo={props.mediaAsset.is_video}
      url={props.mediaAsset.poster_url}
      removable={false}
      isSelected={isSelected}
      endItem={makeEndItem()}
      handleMediaClicked={handleMediaClicked}
    />);
}

export default UserMediaLibraryItem;
