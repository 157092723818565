import { Stack, Typography } from "@mui/material";
import React from "react";
import { AlkaiWordmarkLogoGradient } from "./alkaiWordmarkLogoGradient";

export function CheckoutSignInHeader()
{
  const loginText = () =>
  {
    return standardLogin();
  }

  const standardLogin = () =>
  {
    return <Stack direction="column" textAlign="center" spacing={4}>
      <Typography variant="h4" textAlign="center">Your Own AI Social Media Assistant</Typography>
      <Typography variant="subtitle1">
        Alkai creates custom videos for you,<br/>
        helping you regularly post engaging content and grow your business.
      </Typography>
    </Stack>
  }

  return (
    <Stack direction="column" alignItems="center" spacing={12}>
      <AlkaiWordmarkLogoGradient/>
      {loginText()}
    </Stack>
  );
}
