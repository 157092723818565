import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from "@mui/material";
import { clearBusiness } from "../business/businessSlice";
import { clearPosts } from "../postIdea/postsSlice";
import { businessServices } from "../../services/business.services";
import { ROUTES } from "../constants";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { clearAssistantChat } from "../assistantChat/assistantChatSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { authenticationServices } from "../../services/authentication.services";

export function NewBusinessButton()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const clearChatAndMakeNewBusiness = async () =>
  {
    dispatch( setApplicationLoadingState( true ) );
    dispatch( clearBusiness() );
    dispatch( clearAssistantChat() );
    dispatch( clearPosts() );

    try
    {
      await businessServices.createNewBusiness();
      await authenticationServices.refreshUser();
      navigateWithSearchParams( ROUTES.CHAT );
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "Error creating new business" ) ) );
    }
    finally
    {
      dispatch( setApplicationLoadingState( false ) );
    }
  }

  return (
    <Button aria-label="start new business" variant="outlined" color="error" onClick={clearChatAndMakeNewBusiness}>New Business</Button>
  )
}
