import React from 'react'
import { Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { SyncAlt } from "@mui/icons-material";

export interface SwitchSocialNetworkAccountButtonProps
{
  component: React.ComponentType<ConnectSocialNetworkAccountDrawerProps>;
  onDrawerClosed: () => void;
}

export interface ConnectSocialNetworkAccountDrawerProps
{
  handleCancel: () => void;
  isInitialConnect: boolean;
}

export function SwitchSocialNetworkAccountButton( props: SwitchSocialNetworkAccountButtonProps )
{
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const Component = props.component;

  function openDrawer()
  {
    setDrawerOpened( true );
  }

  function closeDrawer()
  {
    props.onDrawerClosed();
    setDrawerOpened( false );
  }

  function handleSwitchAccounts()
  {
    openDrawer();
  }

  return (
    <>

      <IconButton sx={{ flexBasis: 40, height: 40, flexGrow: 0, flexShrink: 0 }} onClick={handleSwitchAccounts}><SyncAlt/></IconButton>
      <>
        <Drawer
          anchor={"bottom"}
          open={drawerOpened}
          onClose={closeDrawer}
          sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
        >
          <Component handleCancel={closeDrawer} isInitialConnect={false}/>
        </Drawer>
      </>
    </>
  )
}
