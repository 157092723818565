import { useNavigate, useSearchParams } from "react-router-dom";
import { merge } from "lodash";
import { ROUTE_PARAM, ROUTES } from "../constants";

export default function useNavigateWithSearchParams()
{
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParamsAsObject = Object.fromEntries(
    searchParams
  );

  return ( pathname, additionSearchParams = {} ) =>
  {
    const fromPath = window.location.pathname;
    if ( fromPath !== pathname && (fromPath === ROUTES.PLAN || pathname !== ROUTES.PLAN) )
    {
      delete queryParamsAsObject[ROUTE_PARAM.PLAN_TAB_DATE];
    }

    const newSearchParams = merge( queryParamsAsObject, additionSearchParams );
    const newURLSearchParams = new URLSearchParams( newSearchParams );
    setSearchParams( newURLSearchParams );

    const path = {
      pathname,
      search: newURLSearchParams.toString(),
    }
    navigate( path, { state: { prevPath: fromPath } } )
  };
}