import { CommandToDesignCanvas, DesignCanvasMessage, Post } from "./postsSlice";
import { isArray } from "lodash";
import { UrlUtils } from "../utils/urlUtils";
import { TemplateInputData } from "../assistantChat/assistantChatSlice";
import { ALKI_ENV, ENV_CONFIG } from "../../constants";
import { DateUtils } from "../utils/dateUtils";

export interface CanvasUpdaterCallbacks
{
  onAnimationStarted?: () => void;
  onAnimationCompleted?: () => void;
}

export class CanvasUpdater
{
  private iframeId: string;
  private iframe: HTMLIFrameElement;
  private messageListener?: EventListener;
  private callbacks?: CanvasUpdaterCallbacks;
  private post: Post;
  private autoplayWhenInitialized: boolean;

  static getIframeUrl( iFrameId, lastRefreshedAt )
  {
    const clientVersion = DateUtils.getRoundedTimeToActAsClientVersion();
    const cdnBaseUrl = ENV_CONFIG.cdnBaseUrl;
    return cdnBaseUrl + "/common/index.html?id=" + iFrameId + "&clientEnvironment=" + ALKI_ENV + "&enableSentry=true&lastRefreshed="
           + lastRefreshedAt + "&clientVersion=" + clientVersion;
  }

  constructor( iframeId: string, post: Post, autoplayWhenInitialized: boolean = true )
  {
    this.iframeId = iframeId;
    this.post = post;
    this.iframe = document.getElementById( this.iframeId ) as HTMLIFrameElement;
    this.autoplayWhenInitialized = autoplayWhenInitialized;
    if ( !this.iframe )
    {
      console.log( "missing iframe", iframeId );
    }
  }

  public createDesignEventListener()
  {
    this.messageListener = ( event: any ) =>
    {
      if ( !event )
      {
        return;
      }

      let eventData: DesignCanvasMessage | null = null;
      if ( isArray( event.data ) && event.data.length >= 3 )
      {
        eventData = this.parseEventData( event );
      }

      if ( !eventData || eventData.iframeId !== this.iframeId )
      {
        return;
      }

      const messageName = eventData.messageName;

      switch ( messageName )
      {
        case "onJavascriptReady":
          this.handleJavascriptReady();
          break;
        case "onInitializationComplete":
          // this.handleInitializationComplete();
          break;
        case "onAnimationComplete":
          this.handleAnimationComplete();
          break;
        case "designJsError":
          console.log( "designJsError details", event.data[1][0].message );
          // this.handleDesignError( eventData.arguments[0] );
          break;

      }
    };
    window.addEventListener( "message", this.messageListener );
  }

  public setCallbacks( callbacks: CanvasUpdaterCallbacks )
  {
    this.callbacks = callbacks;
  }

  public parseEventData( event: any ): DesignCanvasMessage
  {
    return {
      messageName: event.data[0],
      arguments: event.data[1],
      iframeId: event.data[2],
    };
  }

  public getId()
  {
    return this.iframeId;
  }

  public onRemove()
  {
    if ( this.messageListener )
    {
      window.removeEventListener( "message", this.messageListener );
      this.messageListener = undefined;
    }
  }

  public handleJavascriptReady()
  {
    // load the controls config json for the design
    // load the post json for the post (primary text, secondary text, input media urls,
    // template input data --- how control config data gets in, input media, text
    // design settings --- how design specific values (can start empty)
    // global settings --- extra captions, other foundation presets, etc
    this.setRemixInputData();

    if ( this.autoplayWhenInitialized )
    {
      this.notifyAnimationStarted();
    }
  }

  public handleAnimationComplete()
  {
    this.notifyAnimationCompleted();
  }

  public updatePost( newPost: Post )
  {
    this.post = newPost;
  }

  public setRemixInputData()
  {
    let command: CommandToDesignCanvas = "setRemixInputData";
    this.sendInputData( command );
  }

  private sendInputData( methodName: CommandToDesignCanvas )
  {
    let templateInputData = this.getUpdatedTemplateInputDataWithOutputMode();
    let corsFriendlyTemplateInputData = new UrlUtils().convertAllUrlsToCorsFriendlyUrls( templateInputData );
    const designSettings = this.post.design_settings_data;
    const globalSettings = this.post.global_settings_data;
    const inputDataParameters = [corsFriendlyTemplateInputData, designSettings, globalSettings, false, false, "animatedPreview"];
    this.sendMessageToIframe( methodName, ...inputDataParameters );
  }

  private notifyAnimationStarted()
  {
    if ( !!this.callbacks && !!this.callbacks.onAnimationStarted )
    {
      this.callbacks.onAnimationStarted();
    }
  }

  private notifyAnimationCompleted()
  {
    if ( !!this.callbacks && !!this.callbacks.onAnimationCompleted )
    {
      this.callbacks.onAnimationCompleted();
    }

  }

  private getUpdatedTemplateInputDataWithOutputMode()
  {
    const templateInputData = JSON.parse( this.post.template_input_data ) as TemplateInputData;
    const postIdeaId = this.post.post_idea_id;
    if ( !this.autoplayWhenInitialized )
    {
      const designOutputMode = 'static';
      const outputTemplateInputData = {
        ...templateInputData,
        postIdeaId,
        designOutputMode,
      }
      return JSON.stringify( outputTemplateInputData )
    }

    return JSON.stringify( { ...templateInputData, postIdeaId } );
  }

  public tellDesignToReloadDesignWithAnimationAndNoReveal()
  {
    this.sendMessageToIframe( "reloadDesignWithAnimation", { shouldAnimateReveal: false } );
    this.notifyAnimationStarted();
  }

  public tellDesignToChangeAudioMuted( value: boolean )
  {
    this.sendMessageToIframe( "changeAudioMute", { mute: value } );
  }

  private sendMessageToIframe( methodName: CommandToDesignCanvas, ...args: any[] )
  {
    if ( this.iframe && this.iframe.contentWindow )
    {
      this.iframe.contentWindow.postMessage( [methodName, args], "*" );
    }
  }
}
