import React, { useRef } from "react";
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { includes, map, merge, size } from "lodash";
import PostTypePortrait from "../../assets/post_type_portrait.jpg";
import PostTypeLandscape from "../../assets/post_type_landscape.jpg";
import PostTypeSquare from "../../assets/post_type_square.jpg";
import { PlayCircleOutline } from "@mui/icons-material";
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';

export interface PostingStrategyQuizContentFormatProps
{
  id: string;
  value: string[];
  onQuestionAnswered: ( id: string, value: string[] ) => void;
}

export function PostingStrategyQuizContentFormat( props: PostingStrategyQuizContentFormatProps )
{
  const results = useRef( props.value || [] );
  const postTypes = [
    {
      id: "portrait_video",
      title: "Vertical Video (Reels)",
      backgroundImage: PostTypePortrait,
      foregroundIcon: PlayCircleOutline
    },
    {
      id: "square_image",
      title: "Square Static Image",
      backgroundImage: PostTypeSquare,
      foregroundIcon: LandscapeOutlinedIcon,
    },
    {
      id: "square_video",
      title: "Square Video",
      backgroundImage: PostTypeSquare,
      foregroundIcon: PlayCircleOutline
    },
    {
      id: "landscape_video",
      title: "Landscape Video",
      backgroundImage: PostTypeLandscape,
      foregroundIcon: PlayCircleOutline
    },
    {
      id: "carousel",
      title: "Carousel",
      backgroundImage: PostTypeSquare,
      foregroundIcon: ViewCarouselOutlinedIcon
    },
    {
      id: "landscape_image",
      title: "Landscape Static Image",
      backgroundImage: PostTypeLandscape,
      foregroundIcon: LandscapeOutlinedIcon,
    }
  ];

  function handleChange( event: React.MouseEvent<HTMLElement>, newPostType: string )
  {
    if ( includes( results.current, newPostType ) )
    {
      results.current = results.current.filter( ( postType ) => postType !== newPostType );
    }
    else
    {
      results.current.push( newPostType );
    }
    props.onQuestionAnswered( props.id, results.current );
  }

  return (<Stack spacing={10}>
    <Typography variant="subtitle1">Choose the <b>top 3</b> post formats you'd like to publish.</Typography>

    <ToggleButtonGroup
      color="primary"
      value={results.current}
      exclusive={true}
      onChange={handleChange}
      sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))", gap: "10px" }}
    >
      {map( postTypes, ( postType ) =>
      {
        const isDisabled = size( results.current ) === 3 && !includes( results.current, postType.id )
        const colorForState = isDisabled ? "lightgray" : "black";

        return <ToggleButton key={postType.id} value={postType.id} sx={{ border: "none", "&.Mui-disabled": { border: "none" } }}
                             disabled={isDisabled}>
          <Stack alignItems="center" justifyContent="space-between" sx={{ height: "100%" }}>
            <Stack alignItems="center" justifyContent="center" height="100%">
              <Box sx={{ border: "4px solid", borderColor: colorForState, borderRadius: "5px", position: "relative", width: "max-content" }}>
                {/*image is only used for sizing, so we can draw border at consistent px without dealing with image scale*/}
                <Box component="img" src={postType.backgroundImage} sx={{ width: "75px", visibility: "hidden" }}/>
                <ForegroundIcon icon={postType.foregroundIcon} disabled={isDisabled}/>
              </Box>
            </Stack>
            <Typography variant="subtitle2" sx={{ mt: 5, textTransform: "none" }}>{postType.title}</Typography>
          </Stack>
        </ToggleButton>
      } )}
    </ToggleButtonGroup>
  </Stack>);
}

interface ForegroundIconProps
{
  icon: React.ElementType;
  disabled: boolean;
}

function ForegroundIcon( props: ForegroundIconProps )
{
  const colorForState = props.disabled ? "lightgray" : "#000000";
  const defaultForegroundIconSxProps = { fontSize: "50px", position: "absolute", top: "calc(50% - 25px)", left: "calc(50% - 25px)" }
  const foregroundIconProps = merge( defaultForegroundIconSxProps, { color: colorForState } );
  const IconComponent = props.icon;
  return <IconComponent sx={foregroundIconProps}/>;
}