import { Link, Typography } from "@mui/material";
import { eventTracker } from "../../helpers/eventTracker";
import { getBusinessId } from "../business/businessSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Variant } from "@mui/material/styles/createTypography";

export interface SurveyLinkProps
{
  variant?: Variant;
  surveyId: string;
}

export function SurveyLink( props: SurveyLinkProps )
{
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";

  function getSurveyUrl()
  {
    return `https://www.research.net/r/${props.surveyId}?businessId=${businessId}`;
  }

  function sendSurveyClickedEvent()
  {
    eventTracker.logSurveyLinkClicked( businessId, props.surveyId );
  }

  return (
    <Typography variant={props.variant || "body1"} onClick={sendSurveyClickedEvent}>
      <Link href={getSurveyUrl()} rel="noopener" target="_blank">Help make Alkai better by taking<br/> our survey!</Link>
    </Typography>)
}