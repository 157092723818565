import React, { useState } from "react";
import { Box } from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import { authenticationServices } from "../../services/authentication.services";
import { SIGN_IN_PROVIDER_EMAIL, SIGN_IN_PROVIDER_PHONE_NUMBER } from "../constants";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { PhoneNumberInput } from "./phoneNumberInput";
import { EmailInput } from "./emailInput";
import validator from "validator";
import { CreateAccountSwitchFormLink } from "./createAccountSwitchFormLink";
import { isEmailCrmSource } from "../utils/crmSourceUtils";

export interface SignInProps
{
  onUserInputChanged: ( newInputValue: string ) => void;
  onSignInProviderChangeRequested: () => void;
  onSignInClose: () => void;
  signInProvider: string;
  source: string;
  shownVia: string;
  signInAsGuestInProgress: boolean;
}

export function SignIn( props: SignInProps )
{
  const shouldShowEmailInput = props.signInProvider === SIGN_IN_PROVIDER_EMAIL;
  const shouldShowPhoneNumberInput = props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER;
  const [showLoader, setShowLoader] = useState( false );

  const dispatch = useDispatch();

  const [userInputValue, setUserInputValue] = useState<string>( "" );
  const [isUserInputValid, setIsUserInputValid] = useState<boolean>( false );

  const handleSubmit = async ( event: React.FormEvent<HTMLFormElement> ) =>
  {
    event.preventDefault();

    if ( props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER )
    {
      const phoneIsValid = matchIsValidTel( userInputValue );
      if ( !phoneIsValid )
      {
        dispatch( setAlertMessage( errorAlert( "Enter a valid phone number to continue" ) ) )
        return
      }
    }
    else if ( props.signInProvider === SIGN_IN_PROVIDER_EMAIL )
    {
      const emailIsValid = validator.isEmail( userInputValue );
      if ( !emailIsValid )
      {
        dispatch( setAlertMessage( errorAlert( "Enter a valid email to continue" ) ) )
        return
      }
    }

    eventTracker.logSignInClicked( props.source, props.shownVia )
    setShowLoader( true )

    authenticationServices.createAccountOrSignIn( userInputValue, props.signInProvider ).then( ( response ) =>
    {
      if ( response.verification_required )
      {
        props.onUserInputChanged( userInputValue );
      }
      setShowLoader( false );
    } ).catch( ( error ) =>
    {
      console.log( error );
      dispatch( setAlertMessage( errorAlert( "Unable to create account" ) ) );
      setShowLoader( false );
    } );
  }

  const handleInputChanged = ( value: string, valid: boolean ) =>
  {
    setUserInputValue( value );
    setIsUserInputValid( valid );
  }

  const onSignInProviderChangeRequested = () =>
  {
    setUserInputValue( "" );
    setIsUserInputValid( false );
    props.onSignInProviderChangeRequested();
  }

  function getSwitchToEmailText()
  {
    if ( isEmailCrmSource( props.source ) )
    {
      return "Sign in with email?";
    }
    return "Outside the US or Canada?";
  }

  function getSwitchToPhoneNumberText()
  {
    if ( isEmailCrmSource( props.source ) )
    {
      return "Sign in with phone number?";
    }
    return "Go back";
  }

  const getSwitchSignInProviderLinkText = () =>
  {
    return props.signInProvider === SIGN_IN_PROVIDER_PHONE_NUMBER ? getSwitchToEmailText() : getSwitchToPhoneNumberText();
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}>
      {shouldShowPhoneNumberInput && <PhoneNumberInput handleInputChanged={handleInputChanged}/>}
      {shouldShowEmailInput && <EmailInput handleInputChanged={handleInputChanged}/>}

      <LoadingButton type="submit"
                     fullWidth
                     variant="contained"
                     sx={{ mt: 3, mb: 2 }}
                     disabled={!isUserInputValid}
                     loading={showLoader}>
        Sign in
      </LoadingButton>
      <CreateAccountSwitchFormLink onClick={onSignInProviderChangeRequested}>{getSwitchSignInProviderLinkText()}</CreateAccountSwitchFormLink>
    </Box>
  )
}

