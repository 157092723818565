import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { FACEBOOK_ALKAI_URL, INSTAGRAM_ALKAI_URL } from "../constants";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

export function SocialLinksSection()
{
  return <Stack direction="row">
    <Typography variant="body1">Follow us for tips & tricks!</Typography>
    <Link href={INSTAGRAM_ALKAI_URL} rel="noopener" target="_blank" sx={{ ml: 2 }}>
      <InstagramIcon sx={{ color: "black" }}/>
    </Link>
    <Link href={FACEBOOK_ALKAI_URL} rel="noopener" target="_blank">
      <FacebookIcon sx={{ color: "black" }}/>
    </Link>
  </Stack>;
}