import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { PostIdeaDataAPI } from "./assistantChatSlice";
import { SEARCH_PARAM_KEY_POST_IDEA } from "../constants";
import "./assistantChat.scss"
import { isUserLoggedIn } from "../user/userSlice";
import { useSearchParams } from "react-router-dom";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { FullScreenEditor } from "../editing/editAndSharePostFlow";
import { PostIdeaContext } from "../context/postIdeaContext";
import { postIdeaServices } from "../../services/postIdeaServices";
import { errorReporter } from "../error/errorReporter";
import { addPost } from "../postIdea/postsSlice";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { addPostIdea } from "../postIdea/postIdeaSlice";

export function PostIdeaNotification()
{
  const dispatch = useDispatch();

  const [searchParams,] = useSearchParams();
  const removeSearchParamByKey = useRemoveSearchParamByKey();

  const [notificationPostIdea, setNotificationPostIdea] = useState<PostIdeaDataAPI | null>( null );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );

  useEffect( () =>
  {
    const notificationPostIdeaSlug = searchParams.get( SEARCH_PARAM_KEY_POST_IDEA );
    if ( userIsLoggedIn && !!notificationPostIdeaSlug )
    {
      removeSearchParamByKey( SEARCH_PARAM_KEY_POST_IDEA );
      postIdeaServices.getPostIdeaData( notificationPostIdeaSlug ).then( ( data ) =>
      {
        dispatch( addPostIdea( data ) )
        eventTracker.logPostIdeaNotificationShown( data );
        setNotificationPostIdea( data );
        fetchPostJson( notificationPostIdeaSlug );
      } ).catch( ( error ) =>
      {
        eventTracker.logPostIdeaNotificationError( error?.message );
        dispatch( setAlertMessage( errorAlert( "An error occurred while loading the post. Please try again." ) ) );
        errorReporter.reportErrorToSentry( error );
      } ).finally( () =>
      {
        removeSearchParamByKey( SEARCH_PARAM_KEY_POST_IDEA );
      } );
    }
  }, [] );

  function fetchPostJson( notificationPostIdeaSlug: string )
  {
    postIdeaServices.getPostJson( notificationPostIdeaSlug ).then( ( postJson ) =>
    {
      store.dispatch( addPost( postJson ) )
    } ).catch( ( error ) =>
    {
      errorReporter.reportErrorToSentry( error );
    } );
  }

  function handleCloseEditorFromNotificationPost()
  {
    setNotificationPostIdea( null );
  }

  return <>
    {!!notificationPostIdea && <PostIdeaContext.Provider value={{postIdea: notificationPostIdea}}>
      <FullScreenEditor editable={true}
                        manageOpenStateExternally={true}
                        externalStateOpen={!!notificationPostIdea}
                        handleClose={handleCloseEditorFromNotificationPost}
                        hideTopBar={true}/>
    </PostIdeaContext.Provider>}
  </>
}

