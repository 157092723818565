import { filter, find, isNumber } from "lodash";

import { FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../services/business.services";

export const socialNetworkAccountHelper = {
  needsRefresh,
  getConnectedFacebookPage,
  getConnectedInstagram,
  getSocialNetworksByType,
  getAllFacebookPageAccounts,
  getAllInstagramAccounts,
  getDisplayName,
};

function needsRefresh( socialNetworkAccount?: SocialNetworkAccountAPI ): boolean
{
  return !!socialNetworkAccount && isNumber( socialNetworkAccount.invalid_token_error_count ) && socialNetworkAccount.invalid_token_error_count > 0;
}

function getConnectedFacebookPage( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === FACEBOOK_PAGE && account.enabled );
}

function getConnectedInstagram( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI | undefined
{
  return find( socialNetworkAccounts, account => account.account_type === FACEBOOK_INSTAGRAM && account.enabled );
}

function getAllFacebookPageAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === FACEBOOK_PAGE) );
}

function getAllInstagramAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === FACEBOOK_INSTAGRAM) );
}

function getSocialNetworksByType( socialNetworkAccounts: SocialNetworkAccountAPI[], accountType: string ): SocialNetworkAccountAPI[]
{
  return filter( socialNetworkAccounts, (account => account.account_type === accountType) );
}

function getDisplayName(socialNetwork: SocialNetworkAccountAPI)
{
  if (socialNetwork.account_type === FACEBOOK_PAGE)
  {
    return "Facebook Page";
  }
  else if (socialNetwork.account_type === FACEBOOK_INSTAGRAM)
  {
    return "Instagram"
  }
  return "";
}