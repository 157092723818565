import * as React from 'react';
import { useEffect, useState } from 'react';
import { Divider } from "@mui/material";
import { concat, filter, find, map } from "lodash";
import { FontSetButton } from "./fontSetButton";
import { FontSetAPI, fontSetServices, FontSetStatusAPI } from "../../../services/fontSet.services";
import { PostEditingDrawerContainer } from "../edtingDrawer/postEditingDrawerContainer";
import { PostEditingDrawerCancelApply } from "../edtingDrawer/postEditingDrawerCancelApply";
import { PostEditingDrawerScrollableContainer } from "../edtingDrawer/postEditingDrawerScrollableContainer";
import { BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_ONE_ROW } from "../../constants";
import { CurrentFontSetSection } from "./currentFontSetSection";
import { FontAPI } from "../../../services/font.services";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EditFont } from "./editFont";
import { DrawerTitle } from "../../typography/DrawerTitle";
import { AddFontSetButton } from "./addFontSetButton";
import { EditFontSourceType } from "../../../helpers/trackingConstants";
import { eventTracker } from "../../../helpers/eventTracker";

export interface PostEditingDrawerProps
{
  selectedFontSetSlug?: string;
  applyFontSet: ( fontSet: FontSetAPI ) => void;
  handleDrawerClose: () => void;
  handleCancelClicked: ( selectedFontSetSlug?: string ) => void;
  postIdeaId?: string;
  source: EditFontSourceType;
}

export function FontSetDrawer( props: PostEditingDrawerProps )
{
  const [lastTimeScrollToSelected, setLastTimeScrollToSelected] = useState<number>( 0 );
  const [lastTimeRemoveScrollHint, setLastTimeRemoveScrollHint] = useState<number>( 0 );
  const [lastTimePreventScrollHintAnimation, setLastTimePreventScrollHintAnimation] = useState<number>( 0 );

  const [showLoader, setShowLoader] = useState( false );

  const [fontSets, setFontSets] = useState<FontSetAPI[]>( [] );
  const selectedFontSet = find( fontSets, ( fontSet ) => fontSet.slug === props.selectedFontSetSlug );
  const [fontSetInEditMode, setFontSetInEditMode] = useState<FontSetAPI>();
  const [fontInEditMode, setFontInEditMode] = useState<FontAPI>();

  function loadFontSets()
  {
    fontSetServices.getFontSets( props.postIdeaId ).then( ( response ) =>
    {
      setFontSets( concat( response.business_font_sets || [], response.font_sets ) );
    } ).finally( () =>
    {
      setShowLoader( false );
    } );
  }

  useEffect( () =>
  {
    setShowLoader( true );
    loadFontSets();
  }, [] );

  useEffect( () =>
  {
    setLastTimeScrollToSelected( new Date().getTime() )
  }, [fontSets] );

  const handleFontSetSelected = ( fontSet: FontSetAPI ) =>
  {
    setLastTimeRemoveScrollHint( new Date().getTime() );
    props.applyFontSet( fontSet );
  }

  const onApplyClick = () =>
  {
    props.handleDrawerClose();
  }

  const onCancelClick = () =>
  {
    props.handleCancelClicked( props.selectedFontSetSlug )
  }

  const handleFavoriteFontSet = async ( fontSet: FontSetAPI ) =>
  {
    setLastTimePreventScrollHintAnimation( new Date().getTime() );
    const response = await fontSetServices.favoriteFontSet( fontSet );
    loadFontSets();
    const newFontSet = response.font_set;
    if ( newFontSet && newFontSet.slug !== props.selectedFontSetSlug )
    {
      await handleFontSetSelected( newFontSet );
    }
  }

  const handleUnfavoriteFontSet = async ( fontSet: FontSetAPI ) =>
  {
    setLastTimePreventScrollHintAnimation( new Date().getTime() );
    await fontSetServices.unfavoriteFontSet( fontSet );
    loadFontSets();
  }

  const startEditingFont = ( fontSet: FontSetAPI, font: FontAPI ) =>
  {
    setFontSetInEditMode( fontSet );
    setFontInEditMode( font );
  }

  const updateFontSetAfterCopy = ( fontSet: FontSetAPI ) =>
  {
    props.applyFontSet( fontSet );
    setFontSetInEditMode( fontSet );
    loadFontSets();
  }

  const handleFinishEditingColorPalette = () =>
  {
    setFontSetInEditMode( undefined );
    setFontInEditMode( undefined );
  }

  const handleAddFontSetClicked = async () =>
  {
    eventTracker.logAddFontSetClicked(props.source);
    const response = await fontSetServices.addNewFontSetForBusiness();
    const newFontSet = response.font_set;
    if ( newFontSet )
    {
      eventTracker.logNewFontSetAdded(props.source, newFontSet);
      await props.applyFontSet( newFontSet );
      startEditingFont( newFontSet, newFontSet.fonts[0] );
    }
    loadFontSets();
  }

  return (
    <PostEditingDrawerContainer sx={{ height: "auto", textAlign: "center" }}>
      {fontInEditMode && <IconButton onClick={handleFinishEditingColorPalette} sx={{ position: "absolute", top: 0, left: 0 }}>
        <ArrowBackIcon/>
      </IconButton>}
      <DrawerTitle>Fonts</DrawerTitle>
      {!fontInEditMode && selectedFontSet && <CurrentFontSetSection fontSet={selectedFontSet}
                                                                    favoriteFontSet={handleFavoriteFontSet}
                                                                    unfavoriteFontSet={handleUnfavoriteFontSet}
                                                                    startEditingFont={startEditingFont}/>}
      {fontInEditMode && fontSetInEditMode && <EditFont selectedFont={fontInEditMode}
                                                        fontSet={fontSetInEditMode}
                                                        updateFontSetAfterCopy={updateFontSetAfterCopy}
                                                        source={props.source} />
      }

      <Divider/>
      {!fontInEditMode && <PostEditingDrawerScrollableContainer height={BOTTOM_NAV_SCROLLABLE_CONTENT_HEIGHT_ONE_ROW}
                                                                showLoader={showLoader}
                                                                lastTimeScrollToSelected={lastTimeScrollToSelected}
                                                                lastTimeRemoveScrollHint={lastTimeRemoveScrollHint}
                                                                lastTimePreventScrollHintAnimation={lastTimePreventScrollHintAnimation}>
        <AddFontSetButton handleAddFontSetClicked={handleAddFontSetClicked}/>
        {map( filter( fontSets, ( fontSet ) => fontSet.status === FontSetStatusAPI.Live ),
          ( fontSet ) =>
          {
            const selected = fontSet.slug === props.selectedFontSetSlug;
            return (
              <FontSetButton key={fontSet.slug} fontSet={fontSet} selected={selected} handleFontSetSelected={handleFontSetSelected}/>
            )
          } )}
      </PostEditingDrawerScrollableContainer>}

      {!fontInEditMode && <PostEditingDrawerCancelApply onCancelClick={onCancelClick} onApplyClick={onApplyClick}/>}
    </PostEditingDrawerContainer>
  );
}
