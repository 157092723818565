import { browserUtils } from "../utils/browserUtils";
import { format } from "date-fns";
import { postIdeaServices } from "../../services/postIdeaServices";
import { infoAlert, setAlertMessage } from "../alert/alertSlice";
import { AppDispatch, store } from "../../app/store";
import { clearRecordingState } from "../loadingIndicator/loadingSlice";

export const postVideoFileDownloader = {
  copyCaptionToClipboardAndInformUser,
  startRecording,
  getVideoBlob,
  generateDownloadedVideoFileName,
  createNativeShareFileData,
  sendDownloadComplete,
}

export interface NativeShareData
{
  files: File[];
}

async function startRecording( postIdeaId: string, shareBatchId?: string )
{
  store.dispatch( clearRecordingState() )

  try
  {
    await runWithRetryOnLoadFailed( postIdeaServices.startDownload( postIdeaId, shareBatchId ) );
    const downloadUrl = await runWithRetryOnLoadFailed( postIdeaServices.getDownloadUrlWithTimeout( postIdeaId ) );
    return { downloadUrl: downloadUrl }
  }
  catch (error)
  {
    console.log("Error starting recording/download:", error);
    return Promise.reject( error );
  }
}

async function getVideoBlob( downloadUrl: string )
{
  const fetchedResponse = await runWithRetryOnLoadFailed<Response>( fetch( downloadUrl ) );
  if ( fetchedResponse && fetchedResponse.status === 200 )
  {
    return await fetchedResponse.blob();
  }
  else
  {
    throw new Error( "something went wrong when fetching response to blob." )
  }
}

function generateDownloadedVideoFileName( downloadUrl: string )
{
  const extension = new URL( downloadUrl ).pathname.split( '.' )[1]
  return "alkai_video_" + format( new Date(), "yyyy-mm-dd_hhmmss" ) + "." + extension;
}

function createNativeShareFileData( blob: Blob, downloadFileName: string )
{
  return { files: [new File( [blob], downloadFileName, { type: blob.type } )] };
}

async function sendDownloadComplete( postIdeaId: string, shareBatchId: string | undefined )
{
  return await runWithRetryOnLoadFailed( postIdeaServices.sendDownloadComplete( postIdeaId, shareBatchId ) );
}

async function runWithRetryOnLoadFailed<T>( runnable: Promise<T>, retries: number = 3 )
{
  while ( retries > 0 )
  {
    try
    {
      return await runnable;
    }
    catch (error)
    {
      if ( error instanceof TypeError && error.message === 'Load failed' )
      {
        retries--;
      }
      else
      {
        throw error;
      }
    }
  }
  return Promise.reject( "Error when running network call when downloading video for " + retries + " times." )
}

function copyCaptionToClipboardAndInformUser( postIdeaCaption: string, dispatch: AppDispatch )
{
  if ( browserUtils.copyToClipboard( postIdeaCaption ) )
  {
    dispatch( setAlertMessage( infoAlert( "Caption copied to clipboard", 'top', false ) ) );
  }
}