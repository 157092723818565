import * as React from "react";
import { Avatar, Box } from "@mui/material";
import facebookConnectedIcon from "../../assets/facebookConnectedIcon.png";
import instagramConnectedIcon from "../../assets/instagramConnectedIcon.png";
import { FACEBOOK_INSTAGRAM, FACEBOOK_PAGE } from "../../services/business.services";

export interface SocialAccountProfileIconProps
{
  accountType: string;
  profileImageUrl?: string;
}

export function SocialAccountProfileIcon( props: SocialAccountProfileIconProps )
{
  function getSocialAccountIcon( socialAccountType: string )
  {
    let platformIconStyles = { position: "absolute", top: 25, right: "-20%", height: 20 };
    switch ( socialAccountType )
    {
      case FACEBOOK_PAGE:
        return <Box component="img" src={facebookConnectedIcon} alt="Facebook Icon" sx={platformIconStyles}/>;
      case FACEBOOK_INSTAGRAM:
        return <Box component="img" src={instagramConnectedIcon} alt="Instagram Icon" sx={platformIconStyles}/>;
      default:
        return "";
    }
  }

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Avatar src={props.profileImageUrl} role="presentation"/>
      {getSocialAccountIcon( props.accountType )}
    </Box>
  );
}

