import React, { useEffect, useRef, useState } from 'react'
import "./postPreview.scss";
import { Post } from "./postsSlice";
import { clsx } from "clsx";
import { CanvasUpdater } from "./CanvasUpdater";
import SafeAreaColorCoded from "../../assets/safeAreaColorCoded.png";
import IgReelsUi from "../../assets/igReelsUi.png";
import { AudioButton } from "../buttons/audioButton";
import { getHasAudioInPost, isAnimatedPost, refreshExpiredEpidemicMusicTrack } from "./postDataHelper";
import { InstagramUIOverlay } from "./InstagramUIOverlay";
import { PlayCircleInContainer } from "../ui/playCircleInContainer";
import { Box, Button, Stack, SxProps } from "@mui/material";
import { join, merge } from "lodash";

export interface PostPreviewProps
{
  iframeId: string;
  post: Post;
  showSafeArea: boolean;
  showIgReelsUI: boolean;
  caption?: string;
  showInstagramOverlay: boolean;
  hidePlayButtonOnPause?: boolean;
  showReplayButtonBelow?: boolean;
  showBorder?: boolean;
  customSxProps?: SxProps;
}

export function PostPreview( props: PostPreviewProps )
{
  const canvasUpdaterRef = useRef<CanvasUpdater | null>( null );
  const post = props.post;
  const [lastRefreshed, setLastRefreshed] = useState( new Date().getTime() );
  const [animatingPreview, setAnimatingPreview] = useState( false );
  const hasAudioInPost = getHasAudioInPost( post );
  const [designAudioMuted, setDesignAudioMuted] = useState( true );
  const aspectRatio = post.aspect_ratio;
  const isAnimated = isAnimatedPost( post );

  useEffect( () =>
  {
    return () =>
    {
      if ( !!canvasUpdaterRef.current )
      {
        canvasUpdaterRef.current.onRemove();
      }
    }
  }, [] );

  useEffect( () =>
  {
    setupCanvasListenerIfNeeded();
    if ( !!canvasUpdaterRef.current && !!post )
    {
      canvasUpdaterRef.current.updatePost( post );
      setDesignAudioMuted( true );
      setLastRefreshed( new Date().getTime() ); // this will trigger refresh of iframe url
    }
  }, [post] );

  function onPreviewAnimationStarted()
  {
    setAnimatingPreview( true );
  }

  function onPreviewAnimationCompleted()
  {
    setAnimatingPreview( false );
  }

  function setupCanvasListenerIfNeeded()
  {
    if ( !canvasUpdaterRef.current && !!post )
    {
      const canvasUpdater = new CanvasUpdater( props.iframeId, post );
      canvasUpdater.setCallbacks(
        {
          onAnimationStarted: onPreviewAnimationStarted,
          onAnimationCompleted: onPreviewAnimationCompleted
        }
      );
      canvasUpdater.createDesignEventListener();
      canvasUpdaterRef.current = canvasUpdater;
    }
  }

  async function getOnDesignClicked()
  {
    if ( canvasUpdaterRef.current )
    {
      try
      {
        await refreshExpiredEpidemicMusicTrack( post );
      }
      catch (error)
      {
      }
      canvasUpdaterRef.current.tellDesignToReloadDesignWithAnimationAndNoReveal();
    }
    else
    {
      console.log( "getOnDesignClicked canvasUpdater not ready" );
    }
  }

  function changeAudioMutedInDesign()
  {
    if ( canvasUpdaterRef.current )
    {
      const toggledMuteState = !designAudioMuted;
      canvasUpdaterRef.current.tellDesignToChangeAudioMuted( toggledMuteState );
      setDesignAudioMuted( toggledMuteState );
    }
    else
    {
      console.log( "changeAudioMutedInDesign canvasUpdater not ready" );
    }
  }

  function renderPreview()
  {
    const iframeUrl = CanvasUpdater.getIframeUrl( props.iframeId, lastRefreshed );

    return <div className={clsx( "designCanvasWrapper" )}>
      <iframe id={props.iframeId} title={"designPreview"} className="designIframe" src={iframeUrl} allow="autoplay"/>
      <div className="clickTarget" onClick={getOnDesignClicked}>
        {isAnimated && !props.hidePlayButtonOnPause && !animatingPreview && <PlayCircleInContainer sx={{ background: "rgba(0,0,0,0.5)" }}/>}
      </div>
    </div>;
  }

  let sx = merge( { ...(props.showBorder && { border: "1px solid #9D9D9D", borderRadius: "5px" }) }, props.customSxProps );
  return (
    <div>
      <Box className={join( ["preview", aspectRatio], " " )} sx={sx}>
        {props.showInstagramOverlay && <InstagramUIOverlay caption={props.caption} hasAudioInPost={hasAudioInPost}/>}
        {props.showSafeArea && <img alt="toggle showing safe area" src={SafeAreaColorCoded} className={"safeAreaIgReels"}/>}
        {props.showIgReelsUI && <img alt="toggle showing reels ui" src={IgReelsUi} className={"igReelsUi"}/>}
        {renderPreview()}
        {hasAudioInPost && <AudioButton changeAudioMuted={changeAudioMutedInDesign} audioMuted={designAudioMuted}/>}
      </Box>
      {
        props.showReplayButtonBelow &&
        <Stack alignItems="center" flexDirection="row" justifyContent="center">
          <Button onClick={getOnDesignClicked} variant="contained" color="primary" sx={{ mt: 2 }}>Replay</Button>
        </Stack>
      }
    </div>
  )
}
