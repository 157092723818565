import { NoticeAPI, setInAppNotices } from "../features/inAppNotices/inAppNoticeSlice";
import { getToApi } from "./requestManager";
import { store } from "../app/store";
import { isUserLoggedIn } from "../features/user/userSlice";

const HEALTH_PATH = "health";
const USER_NOTICES_PATH = "user_notices";

export const inAppNoticeServices = {
  getNotices,
}

interface InAppNoticeAPI
{
  in_app_notices: NoticeAPI[];
}

async function getNotices()
{
  const userIsLoggedIn = isUserLoggedIn( store.getState() );
  const endPoint = userIsLoggedIn ? USER_NOTICES_PATH : HEALTH_PATH;
  const response = await getToApi<InAppNoticeAPI>( endPoint );
  store.dispatch( setInAppNotices( response.in_app_notices ) );
}