import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { Box, Button, Divider, FormGroup, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SendIcon from "@mui/icons-material/Send";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { ShareDestinationToggle } from "./ShareDestinationToggle";
import { eventTracker } from "../../helpers/eventTracker";
import { browserUtils } from "../utils/browserUtils";
import { NativeShareData, postVideoFileDownloader } from "./postVideoFileDownloader";
import { PostIdeaContext } from "../context/postIdeaContext";
import { hasCompletedPostIdea } from "../postIdea/postIdeaHelper";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { postIdeaServices, RecordingStatusAPI, ShareBatchAPI } from "../../services/postIdeaServices";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { businessServices, FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../../services/business.services";
import { compact, map, size } from "lodash";
import { NativeShareDownloadAgainDrawerContent } from "./nativeShareDownloadAgainDrawerContent";
import LoadingButton from "@mui/lab/LoadingButton";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialMediaAccountRow } from "../userSettings/SocialMediaAccountRow";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { endOfDay } from 'date-fns';
import { DateTimeValidationError, LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import "./dateTimePicker.scss";
import { RecordingProgressBar } from "./recordingProgressBar";
import { isShareBatchScheduled, roundToNextFifteenMinuteIncrement } from "../postIdea/shareBatchHelper";
import { DateUtils } from "../utils/dateUtils";
import { lastVisibleDayInTwoWeekPlan } from "../planner/planHelper";
import { isGuestUser } from "../user/userSlice";
import { ERROR_MESSAGE_REFRESH_AND_TRY_AGAIN } from "../constants";
import { isSubscribedAllNotifications } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { isStatic, refreshExpiredEpidemicMusicTrack } from "../postIdea/postDataHelper";
import { errorReporter } from "../error/errorReporter";

export enum ShareNowTabContentSources
{
  PLAN = "plan",
  EDIT = "edit",
}

export interface ShareNowTabContentProps
{
  handleShareComplete: ( shareBatch?: ShareBatchAPI ) => void;
  handleBackClicked: () => void;
  previousShareBatch?: ShareBatchAPI;
  sharedFrom: string;
  isScheduling?: boolean;
  initialDate?: Date;
}

export interface NativeShareDownloadPromise
{
  blob: Blob;
  didDownloadWithNativeShare: boolean;
  downloadedFileName: string;
}

export function DirectShare( props: ShareNowTabContentProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ) );
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );
  const isGuest = useSelector( isGuestUser );

  const socialNetworkAccounts = socialNetworkAccountsContext.socialNetworkAccounts;

  const connectedFacebookPage = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
  const connectedInstagram = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );

  const instagramAccountName = connectedInstagram?.name;
  const instagramProfileImageUrl = connectedInstagram?.profile_image_url;
  const facebookPageName = connectedFacebookPage?.name;
  const facebookProfileImageUrl = connectedFacebookPage?.profile_image_url;

  const socialNetworksToShareTo: SocialNetworkAccountAPI[] = [];
  const [downloadSelected, setDownloadSelected] = React.useState( true );
  const [facebookPageSelected, setFacebookPageSelected] = React.useState( false );
  const [instagramSelected, setInstagramSelected] = React.useState( false );
  const [showDownloadLoader, setShowDownloadLoader] = React.useState( false );
  const [failedNativeShareCount, setFailedNativeShareCount] = React.useState( 0 );
  const maxFailedNativeShareAttempts = 3;

  const resolvedDownloadUrlRef = useRef<string>();
  const nativeShareDataRef = useRef<NativeShareData>( { files: [] } );
  const downloadedBlobRef = useRef<Blob | null>( null );
  const downloadedVideoFileNameRef = useRef<string>();
  const completedSharedBatchRef = useRef<ShareBatchAPI>();
  const downloadSharingCompleteRef = useRef<boolean>();
  const socialNetworksSharingCompleteRef = useRef<boolean>();

  const dispatch = useDispatch();
  const postHasBeenCompleted = hasCompletedPostIdea( postIdea );
  const documentVisible = useVisibilityChange();
  const isAppVisibleRef = useRef( documentVisible );
  const [downloadUrl, setDownloadUrl] = React.useState( "" );
  const [isLoadingOverlayShown, setIsLoadingOverlayShown] = React.useState( false );
  const [loadingMessage, setLoadingMessage] = React.useState( "" );
  const minimumScheduledTime = roundToNextFifteenMinuteIncrement( new Date() );
  const isUnsubscribedFromNotifications = !useSelector( ( state: RootState ) => isSubscribedAllNotifications( state ) );
  const showNotificationReminder = isUnsubscribedFromNotifications && downloadSelected && props.isScheduling;

  const endOfVisiblePlan = lastVisibleDayInTwoWeekPlan();
  const maxScheduledDateTimeEndOfDay = endOfDay( endOfVisiblePlan );
  const [scheduledForTime, setScheduledForTime] = React.useState( props.initialDate || minimumScheduledTime );

  const [datePickerError, setDatePickerError] = React.useState<DateTimeValidationError | null>( null );

  const datePickerErrorMessage = React.useMemo( () =>
  {
    switch ( datePickerError )
    {
      case 'minTime':
      {
        return 'Please select a time at least 15 minutes from now';
      }

      default:
      {
        return '';
      }
    }
  }, [datePickerError] );

  function showLoadingOverlay( message: string = "" )
  {
    setLoadingMessage( message );
    setIsLoadingOverlayShown( true );
  }

  function hideLoadingOverlay()
  {
    setIsLoadingOverlayShown( false );
  }

  useEffect( () =>
  {
    onMount();
  }, [] );

  async function onMount()
  {
    eventTracker.logShareOptionsShown( postIdea, props.sharedFrom );
    reloadSocialNetworkAccounts();

    if ( postIdea.recording?.recording_status === RecordingStatusAPI.READY )
    {
      if ( isStatic( post ) )
      {
        setDownloadUrl( postIdea.recording.image_url );
      }
      else
      {
        setDownloadUrl( postIdea.recording.video_url );
      }
    }
    else
    {
      setShowDownloadLoader( true );
      try
      {
        const customErrorMessage = props.sharedFrom === ShareNowTabContentSources.PLAN
                                   ? "There was a problem with the music in this post.  Click Edit Post, update the music, and try sharing again."
                                   : undefined;
        await refreshExpiredEpidemicMusicTrack( post, customErrorMessage );
      }
      catch (error)
      {
        setShowDownloadLoader( false );
        props.handleBackClicked();
        return;
      }

      postVideoFileDownloader.startRecording( postIdea.id ).then( ( result ) =>
      {
        setDownloadUrl( result.downloadUrl );
      } ).catch( ( error ) =>
      {
        console.error( "Automatic recording of post failed:", error )
        errorReporter.reportErrorToSentry( error );
        dispatch(
          setAlertMessage(
            errorAlert( "Unable to create video for download. Please click Share again or contact support if the problem persists." ) ) );
        props.handleBackClicked();
      } ).finally( () =>
      {
        setShowDownloadLoader( false );
      } );
    }
  }

  async function handleShareClicked( event: React.MouseEvent )
  {
    if ( props.isScheduling && DateUtils.isWithinFiveMinutes( scheduledForTime, new Date() ) )
    {
      setDatePickerError( 'minTime' );
      return;
    }

    if ( downloadSelected )
    {
      // this must be done before the request to create share batch, otherwise safari will throw a security error
      postVideoFileDownloader.copyCaptionToClipboardAndInformUser( postIdea.caption, dispatch );
    }
    if ( facebookPageSelected )
    {
      handleShareToFacebookPageClicked( event );
    }
    if ( instagramSelected )
    {
      handleShareToInstagramClicked( event );
    }

    const isDownloadOnly = socialNetworksToShareTo.length === 0;

    try
    {
      showLoadingOverlay( "Processing..." );
      const shareBatchResponse = await postIdeaServices.createShareBatch( postIdea.id, isDownloadOnly, !!props.isScheduling,
        props.previousShareBatch?.id );
      const newShareBatch = shareBatchResponse.share_batch;

      eventTracker.logSharePostClicked( downloadSelected, facebookPageSelected, instagramSelected, postIdea, props.sharedFrom, newShareBatch );

      if ( size( socialNetworksToShareTo ) === 0 )
      {
        socialNetworksSharingCompleteRef.current = true;
      }

      if ( !downloadSelected )
      {
        downloadSharingCompleteRef.current = true;
      }

      if ( downloadSelected )
      {
        await handleDownloadPost( newShareBatch );
      }

      if ( props.isScheduling && scheduledForTime >= minimumScheduledTime )
      {
        await handleSchedulingShareBatch( newShareBatch );
      }
      else if ( !props.isScheduling && socialNetworksToShareTo.length > 0 )
      {
        await handleSharingToSocialNetworks( newShareBatch );
      }
    }
    catch (e)
    {
      console.error( "Error during share:", e );
      reportErrorAndShowUserGenericErrorAlert( e );
    }
    finally
    {
      hideLoadingOverlay();
    }
  }

  async function handleDownloadPost( shareBatch: ShareBatchAPI )
  {
    let resolvedDownloadUrl = downloadUrl;

    if ( !resolvedDownloadUrl )
    {
      try
      {
        let startRecordingResult = await postVideoFileDownloader.startRecording( postIdea.id, shareBatch.id );
        resolvedDownloadUrl = startRecordingResult.downloadUrl;
      }
      catch (error)
      {
        console.error( "Failed to get download url:", error );
        return Promise.reject( error );
      }
    }

    resolvedDownloadUrlRef.current = resolvedDownloadUrl;

    try
    {
      const videoBlob = await postVideoFileDownloader.getVideoBlob( resolvedDownloadUrl );
      downloadedBlobRef.current = videoBlob;

      const downloadedVideoFileName = postVideoFileDownloader.generateDownloadedVideoFileName( resolvedDownloadUrl );
      downloadedVideoFileNameRef.current = downloadedVideoFileName;

      const videoNativeShareFileData = postVideoFileDownloader.createNativeShareFileData( videoBlob, downloadedVideoFileName );
      nativeShareDataRef.current = videoNativeShareFileData;

      if ( !props.isScheduling )
      {
        const downloadCompleteResponse = await postVideoFileDownloader.sendDownloadComplete( postIdea.id, shareBatch.id );
        completedSharedBatchRef.current = downloadCompleteResponse.share_batch
      }

      if ( browserUtils.isNativeShareSupported() )
      {
        try
        {
          await attemptNativeShare( videoNativeShareFileData, failedNativeShareCount, videoBlob, downloadedVideoFileName );
          downloadSharingCompleteRef.current = true;
        }
        catch (e)
        {
          console.error( "Native share failed:", e );
          updateAfterNativeShareFailure();
        }
      }
      else
      {
        downloadViaFallback( videoBlob, downloadedVideoFileName );
        downloadSharingCompleteRef.current = true;
      }
      completeSharing();
    }
    catch (e)
    {
      console.error( "Downloading video failed:", e );
      reportErrorAndShowUserGenericErrorAlert( e );
      eventTracker.logSharePostDownloadFailed( e, isAppVisibleRef.current );
      setShowDownloadLoader( false );
    }
  }

  function reportErrorAndShowUserGenericErrorAlert( e )
  {
    errorReporter.reportErrorToSentry( e );
    dispatch( setAlertMessage( errorAlert( ERROR_MESSAGE_REFRESH_AND_TRY_AGAIN ) ) );
  }

  async function handleSchedulingShareBatch( shareBatch: ShareBatchAPI )
  {
    try
    {
      const socialNetworkIds = compact( map( socialNetworksToShareTo, socialNetwork => socialNetwork.id ) );
      const schedulingResponse = await postIdeaServices.startScheduleShareBatch( postIdea.id, socialNetworkIds, shareBatch.id, scheduledForTime,
        downloadSelected );
      completedSharedBatchRef.current = schedulingResponse.share_batch;

      completeScheduling();
    }
    catch (e)
    {
      console.error( "Start schedule share batch failed:", e );
      return Promise.reject( e );
    }
  }

  function completeScheduling()
  {
    if ( !!completedSharedBatchRef.current )
    {
      sendSharePostCompletedEvents( completedSharedBatchRef.current );
      setShowDownloadLoader( false );
      props.handleShareComplete( completedSharedBatchRef.current );
    }
  }

  async function handleSharingToSocialNetworks( shareBatch: ShareBatchAPI )
  {
    const shareToSocialNetworksResponse = await shareToSocialNetworks( shareBatch );
    socialNetworksSharingCompleteRef.current = true;

    const downloadCompleteResponseAPIPromise = await postIdeaServices.sendDirectShareStartComplete( postIdea.id,
      shareToSocialNetworksResponse.share_batch.id );
    completedSharedBatchRef.current = downloadCompleteResponseAPIPromise.share_batch;

    completeSharing();
  }

  function completeSharing()
  {
    // wait for resolution of both download and social network shares before sending away from share drawer
    if ( downloadSharingCompleteRef.current && socialNetworksSharingCompleteRef.current && !!completedSharedBatchRef.current )
    {
      sendSharePostCompletedEvents( completedSharedBatchRef.current );
      setShowDownloadLoader( false );
      props.handleShareComplete( completedSharedBatchRef.current );
    }
  }

  function handleCancelDownloadAgainClicked()
  {
    downloadSharingCompleteRef.current = true;
    completeSharing();
  }

  async function handleDownloadAgainClicked()
  {
    if ( hasNativeShareFileData() && !!downloadedBlobRef.current && !!downloadedVideoFileNameRef.current )
    {
      try
      {
        const nativeShareDownloadPromise = await attemptNativeShare( nativeShareDataRef.current,
          failedNativeShareCount,
          downloadedBlobRef.current,
          downloadedVideoFileNameRef.current );

        if ( nativeShareDownloadPromise.didDownloadWithNativeShare )
        {
          downloadSharingCompleteRef.current = true;
          completeSharing();
        }
        else if ( failedNativeShareCount === maxFailedNativeShareAttempts )
        {
          downloadViaFallback( nativeShareDownloadPromise.blob, nativeShareDownloadPromise.downloadedFileName );
          downloadSharingCompleteRef.current = true;

          await browserUtils.sleep( 10000 );
          completeSharing();
        }
      }
      catch (e)
      {
        updateAfterNativeShareFailure();
      }
    }
  }

  function shouldTryNativeShare( shareData: NativeShareData, failedNativeShareCount: number )
  {
    return browserUtils.isNativeShareSupported() && navigator.canShare( shareData ) && failedNativeShareCount < maxFailedNativeShareAttempts;
  }

  async function attemptNativeShare( videoNativeShareFileData: { files: File[] },
                                     failedNativeShareCount: number,
                                     videoBlob: Blob,
                                     downloadedVideoFileName: string ): Promise<NativeShareDownloadPromise>
  {
    if ( shouldTryNativeShare( videoNativeShareFileData, failedNativeShareCount ) )
    {
      try
      {
        // Dev debug -- enable this to force native share failure on iOS mobile
        // if ( failedNativeShareCount < maxFailedNativeShareAttempts )
        // {
        //   //  force native share failure by sleeping a long time before kicking of native share
        //   await browserUtils.sleep( 5000 );
        // }

        await navigator.share( videoNativeShareFileData );
        eventTracker.logSharePostSaveCompleted();
        return Promise.resolve( { blob: videoBlob, downloadedFileName: downloadedVideoFileName, didDownloadWithNativeShare: true } );
      }
      catch (error)
      {
        const userCanceledNativeShare = error?.toString().includes( 'AbortError' );
        if ( userCanceledNativeShare )
        {
          eventTracker.logSharePostSaveCanceled();
          return Promise.resolve( { blob: videoBlob, downloadedFileName: downloadedVideoFileName, didDownloadWithNativeShare: false } );
        }
        else
        {
          eventTracker.logSharePostSaveFailed( true, failedNativeShareCount, error );
          return Promise.reject( error );
        }
      }
    }
    else
    {
      return Promise.resolve( { blob: videoBlob, downloadedFileName: downloadedVideoFileName, didDownloadWithNativeShare: false } );
    }
  }

  function downloadViaFallback( blob: Blob, downloadFileName: string )
  {
    const url = window.URL.createObjectURL( blob );
    const a = document.createElement( 'a' );
    a.style.display = 'none';
    a.href = url;
    a.setAttribute( 'download', downloadFileName );
    document.body.appendChild( a );
    a.click();

    setTimeout( () =>
    {
      window.URL.revokeObjectURL( url )
    }, 150 );
  }

  async function shareToSocialNetworks( shareBatch: ShareBatchAPI )
  {
    const shareBatchId = shareBatch.id;

    try
    {
      const socialNetworkIds = compact( map( socialNetworksToShareTo, socialNetwork => socialNetwork.id ) );
      return await postIdeaServices.startShareToSocialNetworks( postIdea.id, socialNetworkIds, shareBatchId );
    }
    catch (e)
    {
      console.error( "Starting share to social networks failed:" )
      return Promise.reject( e );
    }
  }

  const handleShareToInstagramClicked = ( event: React.MouseEvent ) =>
  {
    if ( !!connectedInstagram )
    {
      socialNetworksToShareTo.push( connectedInstagram );
    }
    else
    {
      console.log( "no instagram account" );
      dispatch( setAlertMessage( errorAlert( "no instagram account connected" ) ) )
    }
  }

  const handleShareToFacebookPageClicked = ( event: React.MouseEvent ) =>
  {
    if ( !!connectedFacebookPage )
    {
      socialNetworksToShareTo.push( connectedFacebookPage );
    }
    else
    {
      console.log( "no facebook page" );
      dispatch( setAlertMessage( errorAlert( "no facebook page connected" ) ) )
    }
  }

  function sendSharePostCompletedEvents( shareBatch: ShareBatchAPI )
  {
    if ( !postHasBeenCompleted )
    {
      eventTracker.logSharePostFirstDownloadCompleted( downloadSelected, facebookPageSelected, instagramSelected, postIdea, props.sharedFrom,
        shareBatch );
    }
    eventTracker.logSharePostCompleted( downloadSelected, facebookPageSelected, instagramSelected, postIdea, props.sharedFrom,
      shareBatch, props.previousShareBatch );
  }

  function updateAfterNativeShareFailure()
  {
    setFailedNativeShareCount( failedNativeShareCount + 1 );
  }

  function hasNativeShareFileData()
  {
    return !!nativeShareDataRef.current && nativeShareDataRef.current.files.length > 0;
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  function getInstagramSwitch()
  {
    function handleInstagramConnected()
    {
      hideLoadingOverlay();
      setInstagramSelected( true );
    }

    function handleInstagramConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleInstagramConnectionStarted()
    {
      showLoadingOverlay( "Connecting to Instagram..." );
    }

    if ( !!connectedInstagram )
    {
      const accountIcon = <SocialAccountProfileIcon profileImageUrl={instagramProfileImageUrl} accountType={FACEBOOK_INSTAGRAM}/>;
      if ( socialNetworkAccountHelper.needsRefresh( connectedInstagram ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedInstagram}/>;
      }
      return <ShareDestinationToggle icon={accountIcon}
                                     label={instagramAccountName}
                                     checked={instagramSelected}
                                     onChange={instagramStatusChanged}/>;
    }
    return <ConnectFacebookInstagramButton handleConnectionSucceeded={handleInstagramConnected}
                                           handleConnectionFailed={handleInstagramConnectionFailed}
                                           handleConnectionStarted={handleInstagramConnectionStarted}/>;
  }

  function getFacebookPageSwitch()
  {
    function handleFacebookPageConnected()
    {
      hideLoadingOverlay();
      setFacebookPageSelected( true );
    }

    function handleFacebookConnectionFailed()
    {
      hideLoadingOverlay();
    }

    function handleFacebookConnectionStarted()
    {
      showLoadingOverlay( "Connecting to Facebook..." );
    }

    if ( !!connectedFacebookPage )
    {
      if ( socialNetworkAccountHelper.needsRefresh( connectedFacebookPage ) )
      {
        return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookPage}/>;
      }
      const accountIcon = <SocialAccountProfileIcon profileImageUrl={facebookProfileImageUrl} accountType={FACEBOOK_PAGE}/>;
      return <ShareDestinationToggle icon={accountIcon}
                                     label={facebookPageName}
                                     checked={facebookPageSelected}
                                     onChange={facebookPageStatusChanged}/>
    }
    return <ConnectFacebookPagesButton handleConnectionSucceeded={handleFacebookPageConnected}
                                       handleConnectionFailed={handleFacebookConnectionFailed}
                                       handleConnectionStarted={handleFacebookConnectionStarted}/>;
  }

  function downloadStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setDownloadSelected( !downloadSelected );
  }

  function facebookPageStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setFacebookPageSelected( !facebookPageSelected );
  }

  function instagramStatusChanged( event: React.ChangeEvent<HTMLInputElement>, checked: boolean )
  {
    setInstagramSelected( !instagramSelected );
  }

  function getShareButtonDisabledStatus()
  {
    return !(downloadSelected || facebookPageSelected || instagramSelected) || !!datePickerError;
  }

  function getShareButtonLoadingStatus()
  {
    return showDownloadLoader || isLoadingOverlayShown;
  }

  function renderShareNowButton()
  {
    const onlyDownloadSelected = downloadSelected && !(facebookPageSelected || instagramSelected);
    const shareText = onlyDownloadSelected ? "Download" : "Share";
    const shareButtonText = showDownloadLoader ? "Recording..." : shareText;
    return (<LoadingButton aria-label="download" variant="contained" color="primary"
                           onClick={handleShareClicked}
                           loading={getShareButtonLoadingStatus()}
                           loadingPosition="start"
                           disabled={getShareButtonDisabledStatus()}
                           startIcon={<SendIcon/>}>
      {shareButtonText}
    </LoadingButton>);
  }

  function renderScheduleLaterButton()
  {
    const isScheduled = !!props.previousShareBatch && isShareBatchScheduled( props.previousShareBatch );
    const scheduleButtonText = isScheduled ? "Reschedule" : "Schedule";
    return (<LoadingButton aria-label="download" variant="contained" color="primary"
                           onClick={handleShareClicked}
                           loading={getShareButtonLoadingStatus()}
                           loadingPosition="start"
                           disabled={getShareButtonDisabledStatus()}
                           startIcon={<SendIcon/>}>
      {scheduleButtonText}
    </LoadingButton>);
  }

  function getDownloadLabel()
  {
    return props.isScheduling && !isGuest ? "Download now and remind me to post" : "Download";
  }

  if ( failedNativeShareCount === 0 )
  {
    return (
      <Box sx={{ position: "relative", p: 3 }}>
        <Box>
          {props.isScheduling &&
           <Stack>
             <Stack direction="row" alignItems="center">
               <Typography sx={{ textAlign: "center" }}>
                 Post scheduled for
               </Typography>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                 <MobileDateTimePicker sx={{ pl: "5px", pb: "6px", width: "195px", "& .MuiOutlinedInput-input": { cursor: "pointer" } }}
                                       defaultValue={scheduledForTime}
                                       minutesStep={15}
                                       minDateTime={minimumScheduledTime}
                                       maxDateTime={maxScheduledDateTimeEndOfDay}
                                       onError={( error ) =>
                                       {
                                         setDatePickerError( error )
                                       }}
                                       onChange={( newScheduledForTime ) =>
                                       {
                                         if ( newScheduledForTime )
                                         {
                                           setScheduledForTime( newScheduledForTime );
                                         }
                                       }}
                 />
               </LocalizationProvider>
             </Stack>
             <Typography variant={"caption"} sx={{ color: "red", textAlign: "center" }}>{datePickerErrorMessage}</Typography>
           </Stack>}

          {!props.isScheduling && <Typography sx={{ my: 5, textAlign: "center" }}>How would you like to share?</Typography>}

          <Divider sx={{ mb: 15 }}/>
          {showDownloadLoader && <RecordingProgressBar/>}
          <FormGroup>
            <Stack alignItems={"center"} spacing={2} sx={{ maxWidth: "320px", m: "0 auto" }}>
              <ShareDestinationToggle icon={<CloudDownloadIcon sx={{ width: 40, height: 40, color: "black" }}/>}
                                      label={getDownloadLabel()}
                                      checked={downloadSelected}
                                      onChange={downloadStatusChanged}
              />
              {showNotificationReminder && <Typography variant={"caption"} sx={{ pl: 15, pb: 2 }}>Notifications are off. Please re-enable in settings
                                                                                                  to receive your post
                                                                                                  reminder.</Typography>}
              {getInstagramSwitch()}
              {getFacebookPageSwitch()}
            </Stack>
          </FormGroup>
          <Stack direction={"row"} justifyContent={"space-evenly"} sx={{ width: 320, mx: "auto", my: 10 }}>
            <Button startIcon={<HighlightOffIcon/>} variant="outlined" color="negative" onClick={props.handleBackClicked}>Cancel</Button>
            {props.isScheduling && renderScheduleLaterButton()}
            {!props.isScheduling && renderShareNowButton()}
          </Stack>
        </Box>
        {isLoadingOverlayShown && <ProgressOverlay hideBackDrop={true} wheelColor="primary.main" circleSize={60} message={loadingMessage} sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "white",
          top: 0,
          left: 0
        }}/>}
      </Box>
    )
  }
  else
  {
    return (
      <NativeShareDownloadAgainDrawerContent handleDownloadAgainClicked={handleDownloadAgainClicked}
                                             handleCancelClicked={handleCancelDownloadAgainClicked}/>
    )
  }
}

