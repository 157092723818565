import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import CircularProgress from "@mui/material/CircularProgress";

export interface MediaPreviewDialogProps
{
  open: boolean;
  onClose: () => void;
  mediaUrl: string;
}

export function MediaPreviewDialog( props: MediaPreviewDialogProps )
{
  const { onClose, open, mediaUrl, ...other } = props;

  const handleOk = () =>
  {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogContent sx={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {mediaUrl && <MediaItem url={mediaUrl} showControls={true}/>}
        {!mediaUrl && <CircularProgress/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

