import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { debounce, isEmpty, isEqual, isUndefined, map, merge } from "lodash";
import { Divider, Stack, TextField, Typography } from "@mui/material";
import "./editTextTabContent.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getPostForPostIdea } from "../postIdea/postsSlice";
import { postIdeaServices } from "../../services/postIdeaServices";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { stringUtils } from "../utils/stringUtils";
import { eventTracker } from "../../helpers/eventTracker";
import { PostIdeaContext } from "../context/postIdeaContext";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";

interface EditTexTabContentProps
{
  notifyPendingTextChanges: ( hasPendingChanges: boolean ) => void;
}

export function EditTextTabContent( props: EditTexTabContentProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;
  const post = useSelector( ( state: RootState ) => getPostForPostIdea( state, postIdea.id ), isEqual );
  const globalSettingsJson = JSON.parse( post.global_settings_data );
  const extraCaptionStore = globalSettingsJson.extraCaptionStore;
  const overlayFrameData = globalSettingsJson.overlayFrame || {};
  const underlayFrameData = globalSettingsJson.underlayFrame || {};
  const slideStore = globalSettingsJson.slideStore;
  const globalCaptions = merge( overlayFrameData["captions"], underlayFrameData["captions"] );
  const [loading, setLoading] = useState( false );
  let updatedSlideCaptions = useRef<Record<string, string>>( {} );
  let updatedPostIdeaCaption = useRef<string | undefined>( undefined );
  let hasPendingChanges = useRef<boolean>( false );
  const AUTO_SAVE_DELAY_MS = 2500;

  const debounceSaveText = useCallback( debounce( saveText, AUTO_SAVE_DELAY_MS ), [] );
  const dispatch = useDispatch();

  useEffect( () =>
  {
    return () =>
    {
      if ( hasPendingChanges )
      {
        if ( !!debounceSaveText )
        {
          debounceSaveText.cancel();
        }

        handleDone();
      }
    }
  }, [] );

  function buildStackWithTextField( captionId: string, label: string )
  {
    const extraCaption = extraCaptionStore[captionId];
    const captionText = extraCaption["text"];
    const isEditable = extraCaption["allowTextEdit"] !== false;

    if ( isEditable )
    {
      return (<Stack key={captionId}>
        {buildTextField( captionId, label, captionText, isEditable )}
        <Divider/>
      </Stack>);
    }
    else
    {
      return null;
    }
  }

  function getSlideCaptionTextFields( slideStore: object )
  {
    return (
      <>
        {map( slideStore, ( slideData, id: string ) =>
          {
            const captions = slideData["extraCaptionData"];

            return (<Stack key={id}>
              {!isEmpty( captions ) && <Typography variant="subtitle2" key={id} sx={{ my: 5 }}>{getSlideTitle( id )}</Typography>}
              {map( captions, ( unusedValue, captionId: string ) =>
              {
                return buildStackWithTextField( captionId, "Slide text" );
              } )}
            </Stack>);
          }
        )}
      </>
    )
  }

  function getSlideTitle( id: string )
  {
    const slideNumber = parseInt( id.split( "_" )[1] ) + 1;
    return "Slide " + slideNumber;
  }

  function buildTextField( captionId: string, label: string, captionText: string, isEditable: boolean )
  {
    const adjustedCaptionText = stringUtils.modifyHtmlNewlineBreakRestWithNewlineCharacter( captionText );
    return <TextField
      id={captionId}
      label={label}
      defaultValue={adjustedCaptionText}
      disabled={!isEditable}
      sx={{ mt: 7.5 }}
      onInput={e => updateExtraCaptionStore( e )}
      multiline
      fullWidth/>;
  }

  function getOverlayFrameCaptionTextFields( overlayFrameCaptions: object )
  {
    return (
      <>
        {map( overlayFrameCaptions, ( unusedValue, captionId: string ) =>
          {
            const captionLabel = getLabelForOverlayFrameCaption( captionId );
            return buildStackWithTextField( captionId, captionLabel );
          }
        )}
        <Divider sx={{ mt: 7.5 }}/>
      </>
    )
  }

  function getLabelForOverlayFrameCaption( captionId: string )
  {
    const overlayFrameCaptionLabelMap =
      {
        "name": "name",
        "benefit_statement": "benefit statement",
        "cost": "cost",
        "cta": "call to action",
      };
    const tag = extraCaptionStore[captionId]["tag"];
    return overlayFrameCaptionLabelMap[tag] || "Global text";
  }

  function updateExtraCaptionStore( event: React.FormEvent<HTMLDivElement> )
  {
    const target = event.target as HTMLInputElement
    const id = target.id;
    updatedSlideCaptions.current[id] = target.value;
    props.notifyPendingTextChanges( true );

    hasPendingChanges.current = true;
    debounceSaveText();
  }

  function updatePostIdeaCaption( event: React.FormEvent<HTMLDivElement> )
  {
    const target = event.target as HTMLInputElement
    updatedPostIdeaCaption.current = target.value
    props.notifyPendingTextChanges( true );

    hasPendingChanges.current = true;
    debounceSaveText();
  }

  async function saveText()
  {
    const slideCaptionsChanged = !isEmpty( updatedSlideCaptions.current );
    const postIdeaCaptionChanged = !isUndefined( updatedPostIdeaCaption.current );
    if ( slideCaptionsChanged || postIdeaCaptionChanged )
    {
      eventTracker.logEditPostTextModified( postIdea, slideCaptionsChanged, postIdeaCaptionChanged );
      setLoading( true )
      await postIdeaServices.replaceText( postIdea.id, updatedSlideCaptions.current, updatedPostIdeaCaption.current ).catch( error =>
      {
        dispatch( setAlertMessage( errorAlert( "Something unexpected failed saving text changes. Please try again or contact support if the problem persists." ) ) );
      });
      props.notifyPendingTextChanges( false );

      hasPendingChanges.current = false;
      setLoading( false );
    }
  }

  async function handleDone()
  {
    await saveText();
  }

  return (
    <Stack className="textEditor">
      {!isEmpty( globalCaptions ) && <Typography variant="subtitle2" sx={{ my: 5 }}>Global (appears on all slides)</Typography>}
      {getOverlayFrameCaptionTextFields( globalCaptions )}

      {getSlideCaptionTextFields( slideStore )}

      <Typography variant="subtitle2" sx={{ my: 5 }}>Post caption</Typography>
      <Stack>
        <TextField
          id="postCaption"
          label="caption"
          defaultValue={postIdea.caption}
          onInput={e => updatePostIdeaCaption( e )}
          multiline
          fullWidth/>
      </Stack>
      {loading && <ProgressOverlay/>}
    </Stack>
  );
}
