import { Button, Link, Stack, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import { UserApiData } from "../../services/authentication.services";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { CUSTOMER_SUPPORT_MAILTO_URL } from "../constants";
import { eventTracker } from "../../helpers/eventTracker";

export const KEEP_EXISTING_USER = "keep_existing";
export const KEEP_CURRENT_USER = "keep_current";
export const REMAIN_GUEST_USER = "remain_guest";

export type ExistingUserChoice = typeof KEEP_EXISTING_USER | typeof KEEP_CURRENT_USER | typeof REMAIN_GUEST_USER

export interface ExistingUserChoiceDialogProps
{
  currentUserData?: UserApiData;
  existingUserData?: UserApiData;
  openExistingUserChoiceDialog: boolean;
  closeExistingUserChoiceDialog: ( userChoice: ExistingUserChoice ) => void;
}

export function ExistingUserChoiceDialog( props: ExistingUserChoiceDialogProps )
{
  function chooseKeepExistingUser()
  {
    eventTracker.logRestoreExistingUserClicked();
    props.closeExistingUserChoiceDialog( KEEP_EXISTING_USER );
  }

  function chooseKeepCurrentUser()
  {
    eventTracker.logKeepCurrentUserClicked();
    props.closeExistingUserChoiceDialog( KEEP_CURRENT_USER );
  }

  function closeWithoutChoice()
  {
    props.closeExistingUserChoiceDialog( REMAIN_GUEST_USER );
  }

  const businessName = props.existingUserData?.current_business_name ? props.existingUserData.current_business_name : "not set";
  const createdAtDisplayDate = props.existingUserData?.created_at ? new Date( props.existingUserData.created_at ).toLocaleDateString() : "";
  const lastPostedDisplayDate = props.existingUserData?.created_at ? new Date( props.existingUserData.created_at ).toLocaleDateString() : "never";
  const numberOfPosts = props.existingUserData?.number_of_posts ? props.existingUserData.number_of_posts : 0;

  return (<Dialog
    fullWidth={false}
    maxWidth={"xl"}
    open={props.openExistingUserChoiceDialog}
  >
    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ position: "absolute", top: 10, right: 10 }}>
      <IconButton onClick={closeWithoutChoice}>
        <ClearIcon/>
      </IconButton>
    </Stack>
    <Typography variant={"h5"} sx={{ textAlign: "center", fontWeight: "bold", color: "#3f50b5", mb: 10, mt: 20 }}>Prior Account Found</Typography>
    <Typography variant={"subtitle1"} sx={{ textAlign: "center", lineHeight: "1.3" }}>I found another account <br/> attached to this
                                                                                      phone/email</Typography>
    <Stack sx={{ background: "#f5f0f2", border: "1px solid black", borderRadius: "20px", alignItems: "center", m: 10, p: 5 }}>
      <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>Previous Account</Typography>
      <Typography sx={{ maxWidth: 250 }}>Business: {businessName}</Typography>
      <Typography>Created date: {createdAtDisplayDate}</Typography>
      <Typography>Last posted date: {lastPostedDisplayDate}</Typography>
      <Typography># of posts completed: {numberOfPosts}</Typography>
    </Stack>
    <Typography variant={"h6"} sx={{ textAlign: "center", mb: 4 }}>Choose one to continue:</Typography>
    <Stack spacing={8} sx={{ px: 10, mb: 10 }}>
      <Button variant={"contained"} onClick={chooseKeepCurrentUser} sx={{ textTransform: "none" }}>
        <Stack>
          <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>Continue with current account</Typography>
          <Typography variant={"subtitle2"}>(Previous account will be deactivated)</Typography>
        </Stack>
      </Button>
      <Button variant={"contained"} onClick={chooseKeepExistingUser} sx={{ textTransform: "none" }}>
        <Stack>
          <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>Restore previous account</Typography>
          <Typography variant={"subtitle2"}>(Current progress will be lost)</Typography>
        </Stack>
      </Button>
      <Typography variant={"caption"} sx={{ textAlign: "center" }}>Questions? <Link sx={{ color: "black" }} href={CUSTOMER_SUPPORT_MAILTO_URL}>Contact
                                                                                                                                               support</Link></Typography>
    </Stack>
  </Dialog>)
}
