import React, { useEffect } from "react";
import { convertPost, Post } from "../postIdea/postsSlice";
import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import { businessServices } from "../../services/business.services";
import { Box, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { FullPageMobileHeader } from "../layout/fullPageMobileHeader";
import { useDispatch } from "react-redux";
import { PostVariationPanel } from "../postIdea/postVariationPanel";
import { BrandCardPreviewSource, NAVIGATE_NEXT, NAVIGATE_PREVIOUS, PREVIEW_NAV_ID_NEXT_VARIANT, PREVIEW_NAV_ID_PREVIOUS_VARIANT } from "../constants";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import AlertBanner from "../alert/alertBanner";
import { eventTracker } from "../../helpers/eventTracker";
import { BrandCardPreview } from "./brandCardPreview";

export interface BrandCardDialogContentsProps extends WithFullScreenDialogProps
{
  showInstagramOverlay: boolean;
  source: BrandCardPreviewSource;
}

export function BrandCardSelector( props: BrandCardDialogContentsProps )
{
  const dispatch = useDispatch();
  const [post, setPost] = React.useState<Post | undefined>( undefined );
  const [showLoader, setShowLoader] = React.useState( false );

  useEffect( () =>
  {
    businessServices.getBrandCardPreview().then( ( brandCardPreview ) =>
    {
      if ( !!brandCardPreview )
      {
        const convertedPost = convertPost( brandCardPreview );
        setPost( convertedPost );
      }
    } );
  }, [] );

  function handleNextVariantRequested()
  {
    handleVariantRequested( NAVIGATE_NEXT );
  }

  function handlePreviousVariantRequested()
  {
    handleVariantRequested( NAVIGATE_PREVIOUS )
  }

  function handleVariantRequested( direction: string )
  {
    setShowLoader( true );
    businessServices.updateBrandCardPreview( direction ).then( ( brandCardPreview ) =>
    {
      let brand_slide_layout: string | undefined = undefined;
      if ( !!brandCardPreview )
      {
        const convertedPost = convertPost( brandCardPreview.post );
        setPost( convertedPost );
        brand_slide_layout = brandCardPreview.brand_slide_config.brand_slide_config_class;
        eventTracker.logBrandCardLayoutSwitched( props.source, brand_slide_layout );
      }
    } ).catch( () =>
      {
        dispatch( setAlertMessage( errorAlert( "There was a problem switching brand cards", 'top' ) ) );
      }
    ).finally( () =>
    {
      setShowLoader( false );
    } );
  }

  return (
    <>
      {!!post &&
       <>
         <FullPageMobileHeader>
           <Stack direction="row" alignItems="flex-start" spacing={2}>
             <IconButton onClick={props.handleClose}>
               <ClearIcon/>
             </IconButton>
             <Typography variant="h6" alignSelf="center" component="div">Brand Card Preview</Typography>
           </Stack>
         </FullPageMobileHeader>
         <FullPageMobileContainer sx={{ alignItems: "center", justifyContent: "center" }}>
           <Box sx={{
             display: "flex",
             flexDirection: "row",
             alignItems: "center",
             margin: "0 auto",
             width: "100%",
             justifyContent: 'center',
             flexGrow: 1
           }}>
             <Box sx={{ display: "flex", margin: "0 auto", justifyContent: "center", gap: 1, flexGrow: 1 }}>
               <PostVariationPanel showLoader={showLoader}
                                   variantDirection={PREVIEW_NAV_ID_PREVIOUS_VARIANT}
                                   handleArrowClick={handlePreviousVariantRequested}
               />
               {post && <BrandCardPreview post={post} showInstagramOverlay={props.showInstagramOverlay} showReplay={true}/>}
               <PostVariationPanel showLoader={showLoader}
                                   variantDirection={PREVIEW_NAV_ID_NEXT_VARIANT}
                                   handleArrowClick={handleNextVariantRequested}
               />
             </Box>
           </Box>
           <AlertBanner/>
         </FullPageMobileContainer>
       </>}
    </>);
}

export const BrandCardSelectorFullScreen = withFullScreenDialog( BrandCardSelector )
